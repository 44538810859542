/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import {
  ContentHeader,
  Container,
  SmallText,
  TitleHeader,
  ExcerptText,
  IBMLogoBox,
  Flex,
  BtnPlay,
  BtnDefault,
  BtnText,
  RecordButton,
  Message,
} from '../../assets/styles/global'
import { TestTitle, TestText, BoxAudio, PlayAudioButton, MessagePlay } from './styles'
import { ReactComponent as IBMLogo } from '../../assets/svg/ibm-logo.svg'
import { ReactComponent as IconPlay } from '../../assets/svg/play.svg'
import { ReactComponent as Airplane } from '../../assets/svg/airplane.svg'
import { Animated } from 'react-animated-css'
import RecordingData from '../../assets/json/recording.json'
import SuccessData from '../../assets/json/success.json'
import CountdownData from '../../assets/json/countdown.json'
import { ReactComponent as Microphone } from '../../assets/svg/microphone.svg'
import { ReactComponent as Poligono } from '../../assets/svg/poligono.svg'
import Lottie from 'react-lottie'
import SoundData from '../../assets/json/sound.json'
import SoundExampleData from '../../assets/json/sound2.json'
import { ReactComponent as IconCheck } from '../../assets/svg/check.svg'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import StudentModalContent from '../../components/StudentModalContent'
import RedoDiagnosticModalContent from '../../components/RedoDiagnosticModalContent'
import { useBlockerModal } from '../../context/blockerModal.context'
import ModalDefault from '../../components/ModalDefault'
import theme from '../../styles/theme'
import useRecorder from '../../hooks/useRecord'

export default function MicTest(props) {
  const { diagnosticId, studentId } = useParams()

  const [recording, setRecording] = useState('stop')
  const [intentText, setIntentText] = useState('Clique para testar seu microfone')
  const [isDisabled, setIsDisabled] = useState(false)
  const [recordDisabled, setRecordDisabled] = useState(true)
  const [playAudio, setPlayAudio] = useState(false)
  const [audioBlob] = useState()
  const [showPlay, setShowPlay] = useState(false)
  const [isDisabledButton, setIsDisabledButton] = useState(true)
  const [redirect, setRedirect] = useState(false)
  const [btnPlayText, setBtnPlayText] = useState('Clique aqui')
  const [playExample, setPlayExample] = useState(false)
  const [exampleIndex, setExampleIndex] = useState(0)
  const [buttonColor, setButtonColor] = useState('#505050')
  const [englishStudent, setEnglishStudent] = useState({})
  const [contentModal, setContentModal] = useState()
  const [hideModal, setHideModal] = useState(true)
  const { setBlockerModal } = useBlockerModal()
  const [newDiagnosticId, setNewDiagnosticId] = useState()
  const queryParameters = new URLSearchParams(window.location.search)

  const [micError, audioURL, audioData, iniciar] = useRecorder()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: RecordingData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const successAnimation = {
    loop: false,
    autoplay: true,
    animationData: SuccessData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const countdownAnimation = {
    loop: false,
    autoplay: true,
    animationData: CountdownData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const soundOptions = {
    loop: true,
    autoplay: true,
    animationData: SoundData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const soundExampleOptions = {
    loop: true,
    autoplay: true,
    animationData: SoundExampleData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const exampleAudios = [
    {
      text: 'What is your name?',
      url: 'https://conquerlms.s3-sa-east-1.amazonaws.com/curso-ingles/what-is-your-name.wav',
    },
    {
      text: 'How old are you?',
      url: 'https://conquerlms.s3-sa-east-1.amazonaws.com/curso-ingles/how-old-are-you.wav',
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
    checkMicrophonePermissions()
  }, [])

  useEffect(() => {
    if (englishStudent && englishStudent?.name && englishStudent?.email) {
      createDiagnosticIfUserIsStudent(studentId)
    }
  }, [englishStudent, studentId])

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  function checkMicrophonePermissions() {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {
        // O microfone foi encontrado e o acesso foi concedido
        setRecordDisabled(false)
      })
      .catch(function (error) {
        if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
          // Nenhum dispositivo de áudio encontrado
          setRecordDisabled(true)
          setIntentText('Nenhum microfone encontrado. Verifique a conexão do dispositivo.')
        } else if (error.name === 'PermissionDeniedError' || error.name === 'NotAllowedError') {
          // Permissão negada pelo usuário
          setRecordDisabled(true)
          setIntentText(
            'Permissão de microfone negada. Ative as permissões nas configurações do navegador.',
          )
        } else {
          // Outro erro
          setRecordDisabled(true)
          setIntentText('Ocorreu um erro ao acessar o microfone. Tente novamente.')
        }
      })
  }

  function createDiagnosticIfUserIsStudent(studentId) {
    if (studentId) {
      createDiagnostic({
        name: englishStudent?.name,
        email: englishStudent?.email,
        phone: '12345678910',
        termAcceptanceLGPD: true,
        timeStudiedEnglish: 'NA',
        mainObjectiveStudyingEnglish: 'NA',
        stuckEnglishJustify: 'NA',
        difficultyToSpeakEnglish: 'NA',
        origin: 'RECORDED_ENGLISH',
      })
    }
  }

  useEffect(() => {
    checkIfUserIsAreadyStudent(getParameterByName('code') || studentId)
  }, [studentId])

  function checkIfUserIsAreadyStudent(studentId) {
    if (studentId) {
      axios.get(`${process.env.REACT_APP_HOST}/student/${studentId}`).then((resp) => {
        if (resp.data.englishStudent) {
          setEnglishStudent(resp.data)
        } else {
          setEnglishStudent({
            name: queryParameters.get('name'),
            email: queryParameters.get('email'),
            englishStudent: true,
          })
        }
      })
    }
  }

  async function createDiagnostic(data) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST}/english-diagnostic/sociodemographic/profile`,
        { ...data },
      )
      setNewDiagnosticId(response.data.payload.diagnosticId)
    } catch (error) {
      if (error && error.response && error.response.status === 400) {
        if (error.response.data.msg && error.response.data.msg === 'Email já cadastrado') {
          setContentModal(<StudentModalContent />)
        } else if (error.response.data.msg && error.response.data.msg.includes('aluno informado')) {
          setContentModal(<RedoDiagnosticModalContent />)
        } else {
          setContentModal(
            <StudentModalContent text='Ops! Algo deu errado, tente novamente. Err: 4001' />,
          )
        }
        setHideModal(false)
      } else if (error && error.response && error.response.status === 500) {
        setBlockerModal({
          status: true,
          text: 'Ops! Algo deu errado, tente novamente. Err: 5001',
        })
      } else {
        // setBlockerModal({ status: true, text: "Ops! Algo deu errado, tente novamente." })
        setContentModal(
          <StudentModalContent text='Ops! Algo deu errado, tente novamente. Err: 5002' />,
        )
        setHideModal(false)
      }
    }
  }

  function fnRedirect() {
    if (isDisabledButton) return
    setRedirect(true)
  }

  function fnPlayAudio() {
    const audioUrl = URL.createObjectURL(audioBlob)
    const audio = new Audio(audioUrl)

    audio.addEventListener('ended', () => {
      setPlayAudio(false)
    })

    audio.play()

    setPlayAudio(!playAudio)
  }

  async function RecordAudio() {
    if (recording === 'countdown' || recordDisabled) return

    if (recording === 'recording') {
      setRecording('success')
      setShowPlay(true)

      setButtonColor(theme.color.SECONDARY)
      setIsDisabledButton(false)
      iniciar(false)
    } else if (recording === 'success') {
      setIsDisabledButton(true)
      setRecording('countdown')
      setIntentText('Aguarde a contagem para falar')

      setTimeout(() => {
        setTimeout(() => {
          iniciar(true)
          setRecording('recording')
          setIntentText('Clique para parar de gravar')
        }, 1000)
      }, 2000)
    } else {
      if (!isDisabled) {
        setRecording('countdown')
        setIntentText('Aguarde a contagem para falar')

        setTimeout(() => {
          setTimeout(() => {
            iniciar(true)
            setRecording('recording')
            setIntentText('Clique para parar de gravar')
          }, 1000)
        }, 2000)
      }
    }
  }

  function playExampleAudio() {
    if (playExample) return

    const example = exampleAudios[exampleIndex]

    setBtnPlayText(example.text)
    var audio = new Audio(example.url)
    setPlayExample(true)
    audio.play()
    audio.addEventListener('ended', function () {
      audio.currentTime = 0
      setPlayExample(false)
      setBtnPlayText('Clique aqui')
      setExampleIndex(exampleIndex === 0 ? 1 : 0)
    })
  }

  function redirectHelp() {
    window.open(process.env.REACT_APP_HELP_URL, '_blank')
  }

  useEffect(() => {
    if (audioData != undefined && props.onFinishRecording) {
      props.onFinishRecording(audioData)
    }
  }, [audioData])

  useEffect(() => {
    if (audioURL != undefined && props.onAudioURL) {
      props.onAudioURL(audioURL)
    }
  }, [audioURL])

  useEffect(() => {
    if (micError != undefined) {
      setIsDisabled(micError)

      if (props.onMicError) {
        props.onMicError(micError)
      }
    }
  }, [micError])

  return (
    <>
      {redirect && studentId && newDiagnosticId ? (
        <Navigate to={`/student/${studentId}/diagnostic/${newDiagnosticId}/your-skills`} />
      ) : (
        ''
      )}
      {redirect && diagnosticId ? <Navigate to={`/diagnostic/${diagnosticId}/your-skills`} /> : ''}

      <ContentHeader>
        <Container>
          <Animated
            animationIn='fadeIn'
            animationOut='fadeIn'
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={true}
          >
            <SmallText>Are you ready?</SmallText>
            <TitleHeader>
              ANTES DE<span> COMEÇAR </span>...
            </TitleHeader>
            <ExcerptText>Temos algumas recomendações para você!</ExcerptText>
          </Animated>
        </Container>
        <Animated
          animationIn='fadeIn'
          animationOut='fadeIn'
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={true}
        >
          <IBMLogoBox>
            <IBMLogo />
          </IBMLogoBox>
        </Animated>
      </ContentHeader>

      <Container>
        <Flex FlexDirection='column' className='bg_secondary'>
          <TestTitle>Você terá que falar ou ouvir algumas frases durante as atividades:</TestTitle>
          <TestText>
            <Airplane />
            Use fones de ouvido para melhor captação e entendimento nos áudios.
          </TestText>
          <TestText>
            <Airplane />
            Encontre um local mais reservado, sem muitos ruídos.
          </TestText>
          <TestText>
            <Airplane />
            Faça um teste abaixo gravando a sua voz.
          </TestText>
          <TestText>
            <Airplane />
            Certifique-se de que o seu microfone está habilitado/permitido pelo navegador.
          </TestText>
          <TestText>
            <Airplane />
            No decorrer do teste, busque gravar seu áudio com a mesma velocidade e clareza do
            exemplo abaixo.
          </TestText>
          <TestText>
            <Airplane />
            <BtnPlay onClick={() => playExampleAudio()} playing={playExample}>
              {btnPlayText}
              {playExample ? (
                <Lottie
                  options={soundExampleOptions}
                  height={45}
                  width={45}
                  isStopped={false}
                  isPaused={false}
                />
              ) : (
                <IconPlay height='10px' />
              )}
            </BtnPlay>{' '}
            para escutar um exemplo de gravação.
          </TestText>
        </Flex>
        <Flex
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <BoxAudio>
            <PlayAudioButton onClick={() => fnPlayAudio()} active={playAudio} Show={showPlay}>
              {playAudio ? (
                <Lottie
                  options={soundOptions}
                  height={20}
                  width={30}
                  isStopped={false}
                  isPaused={false}
                />
              ) : (
                <IconPlay height='20px' />
              )}
            </PlayAudioButton>
            <RecordButton
              onClick={() => RecordAudio()}
              recording={recording}
              recordDisabled={recordDisabled}
            >
              {recording === 'countdown' ? (
                <Lottie
                  options={countdownAnimation}
                  height={100}
                  width={100}
                  isStopped={false}
                  isPaused={false}
                  isClickToPauseDisabled
                />
              ) : recording === 'recording' ? (
                <Lottie
                  options={defaultOptions}
                  height={100}
                  width={100}
                  isStopped={false}
                  isPaused={false}
                  isClickToPauseDisabled
                />
              ) : recording === 'success' ? (
                <Lottie
                  options={successAnimation}
                  height={100}
                  width={100}
                  isStopped={false}
                  isPaused={false}
                  isClickToPauseDisabled
                />
              ) : (
                <Microphone />
              )}
            </RecordButton>
          </BoxAudio>

          {showPlay ? (
            <>
              <MessagePlay>
                <Poligono />
                <strong>Tudo certo! Vamos começar!</strong>
              </MessagePlay>{' '}
            </>
          ) : (
            <Message>
              <Poligono />
              {intentText}
            </Message>
          )}
        </Flex>
      </Container>
      <Container>
        <Flex
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <BtnDefault
            disabled={isDisabledButton}
            onClick={() => redirectHelp()}
            backgroundColor={theme.color.GREY100}
            marginRight='0px'
            textAlign='left'
          >
            <FontAwesomeIcon icon={faTimes} size='1x' />
            <BtnText>não, help me</BtnText>
          </BtnDefault>
          <BtnDefault
            disabled={isDisabledButton}
            onClick={() => fnRedirect()}
            backgroundColor={buttonColor}
            marginRight='0px'
            textAlign='right'
          >
            <BtnText>sim, let&apos;s go</BtnText>
            <IconCheck />
          </BtnDefault>
        </Flex>
      </Container>

      <ModalDefault
        hideModal={hideModal}
        modalContent={contentModal}
        closeModal={(e) => setHideModal(e)}
      />
    </>
  )
}
