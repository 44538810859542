import styled from 'styled-components'
import theme from '../../styles/theme'

export const BoxAudio = styled.div`
  position: relative;
`

export const TestTitle = styled.h2`
  color: white;
  font-weight: bold;
  font-size: 24px;
  margin-top: 58px;
`
export const TestText = styled.div`
  color: white;
  font-weight: 300;
  display: flex;
  align-items: center;
  margin: 12px 0px;
  font-size: 20px;
  padding-left: 20px;
  & svg {
    margin-right: 14px;
    min-width: 20px;
  }
`

export const PlayAudioButton = styled.div`
  display: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.active ? theme.color.SECONDARY : theme.color.GREY500)};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: absolute;
  left: -80px;
  top: 65%;
  transform: translateY(-50%);
`

export const MessagePlay = styled.div`
  background-color: white;
  padding: 30px;
  color: ${theme.color.GREY};
  font-weight: 300;
  font-size: 16px;
  position: relative;
  margin-top: 20px;
  line-height: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  & svg {
    position: absolute;
    left: 50%;
    top: -10px;
    transform: translateX(-50%);
    & > path {
      fill: white;
    }
  }
`
