import React, { createContext, useState, useContext } from 'react'
const FinishContext = createContext()

export default function FinishProvider({ children }) {
  const [finishInfo, setFinishInfo] = useState()

  return (
    <FinishContext.Provider value={{ finishInfo, setFinishInfo }}>
      {children}
    </FinishContext.Provider>
  )
}

export function useFinish() {
  const context = useContext(FinishContext)
  const { finishInfo, setFinishInfo } = context
  return { finishInfo, setFinishInfo }
}
