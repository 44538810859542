import React, { useState } from 'react'
import { Flex, Question, RecordButton, Answers, Divider } from '../../assets/styles/global'
import { ReactComponent as PlayIcon } from '../../assets/svg/play.svg'
import Lottie from 'react-lottie'
import SoundData from '../../assets/json/sound.json'

export default function ListenAudioQuestion(props) {
  const [playSound, setPlaySound] = useState(false)
  const [selectedId, setSelectedId] = useState('')

  function playMedia(media) {
    var audio = new Audio(media)
    setPlaySound(true)
    audio.play()
    audio.addEventListener('ended', function () {
      audio.currentTime = 0
      setPlaySound(false)
    })
  }

  function sendCallback(answerid) {
    props.callBack({ questionId: props.question.id, answer: answerid })
    setSelectedId(answerid)
    props.changeNext(false)
  }

  const soundOptions = {
    loop: true,
    autoplay: true,
    animationData: SoundData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <>
      <Flex
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Question>Selecione a opção que corresponde ao áudio:</Question>
        <RecordButton onClick={() => playMedia(props.question.mediaUrl)}>
          {playSound ? (
            <Lottie
              options={soundOptions}
              height={50}
              width={50}
              isStopped={false}
              isPaused={false}
            />
          ) : (
            <PlayIcon />
          )}
        </RecordButton>
        <Divider />
        {props.question.answers.map((answer) => (
          <Answers
            key={answer.id}
            onClick={() => sendCallback(answer.id)}
            selected={selectedId === answer.id ? true : false}
          >
            {answer.text}
          </Answers>
        ))}
      </Flex>
    </>
  )
}
