import { React } from 'react'

import PhoneInput from 'react-phone-input-2'
import './style.css'

export default function PhoneInput2(props) {
  function handleOnChange(e) {
    if (props.onChange) {
      let value = {
        target: {
          name: props.name,
          value: e,
        },
      }
      props.onChange(value)
    }
  }

  return (
    <>
      <PhoneInput
        country={'br'}
        value={props.phone}
        onChange={handleOnChange}
        enableSearch={true}
        containerClass={'conquer-phone-container'}
        inputClass={'conquer-phone-input'}
        onBlur={props.onBlur}
      />
    </>
  )
}
