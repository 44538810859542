import styled from 'styled-components'
import theme from '../../styles/theme'

export const Footer = styled.footer`
  background-color: ${theme.color.MAIN_BG} !important;
  padding: 5rem;
  border-top: 5px black solid;

  .social {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;

    img {
      width: 1.8rem;
      cursor: pointer;
    }
  }

  ul {
    padding: 2rem 0;
  }

  li {
    list-style: none;
    cursor: pointer;
    color: var(--white);
    width: fit-content;

    &:first-child {
      font-weight: 700;
    }

    & + li {
      margin-top: 0.5rem;
    }

    &:first-child {
      cursor: unset;
    }
  }

  .buttons {
    display: frex;
    gap: 0.5rem;

    button {
      padding: 1rem 0.5rem;
      background-color: ${theme.color.PRIMARY};
      color: ${theme.color.WHITE};
      cursor: pointer;
    }
  }

  .newsletter {
    margin-top: 2rem;
    color: white;

    button {
    }

    input {
      color: var(--white);
    }

    label {
      font-weight: 700;
    }
  }

  .newsletter > div {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 1.5fr 0.5fr;
    align-items: center;
    gap: 0.5rem;
  }

  .newsletter input,
  .newsletter button {
    height: 2.5rem;
    padding: 0;
    width: 100%;
  }

  .newsletter input {
    background-color: transparent;
    border: none;
    border-bottom: ${theme.color.PRIMARY} 1px solid;
  }

  .newsletter button {
    padding: 0 0.5rem;
  }

  @media (min-width: 768px) {
    background-color: var(--grey-dark);
    > div {
      display: grid;
      grid-template-columns: 0.3fr 1.7fr auto;
      column-gap: 1.5rem;
      position: relative;

      * {
        margin: 0;
        padding: 0;
      }
    }

    .social {
      position: absolute;
      bottom: 0;
      left: 17%;
    }

    .content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
      align-items: start;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      margin: auto 0 0 0;

      button {
        max-width: fit-content;
      }
    }
  }
`

export const Lgpd = styled.aside`
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: ${theme.color.BLACK};
  color: ${theme.color.WHITE};
  font-size: 0.8rem;
  text-align: center;
`
