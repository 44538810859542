import React, { useState } from 'react'
import { Flex, Question, Answers, Divider, TitleQustion } from '../../assets/styles/global'

export default function AlternativeQuestion(props) {
  const [selectedId, setSelectedId] = useState('')

  function sendCallback(answerId) {
    props.callBack({ questionId: props.question.id, answer: answerId })
    setSelectedId(answerId)
    props.changeNext(false)
  }

  return (
    <>
      <Flex
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <TitleQustion>
          Selecione a opção que contém a equivalência em inglês da frase abaixo:
        </TitleQustion>
        <Question>{props.question.text}</Question>
        <Divider />
        {props.question.answers.map((answer) => (
          <Answers
            key={answer.id}
            onClick={() => sendCallback(answer.id)}
            selected={selectedId === answer.id ? true : false}
          >
            {answer.text}
          </Answers>
        ))}
      </Flex>
    </>
  )
}
