import * as Style from './styles'
import Button from './button'
import EmailValidator from 'email-validator'
import { useState } from 'react'
import axios from 'axios'
import gtmEvent from '../../utils/gtmEvent'
import theme from '../../styles/theme'

const Footer = () => {
  const [newsletterEmail, setNewsletterEmail] = useState('')
  const [submitMessage, setSubmitMessage] = useState('')

  function handleChangeNewsletterEmail(e) {
    setNewsletterEmail(e.target.value)
  }

  function linkTo(url, tab) {
    window?.open(url, tab)
  }

  async function handleNewsletterSubmit() {
    if (!EmailValidator.validate(newsletterEmail)) {
      return
    }
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_MKT}/lead-api/lead/newsletter?email=${newsletterEmail}`,
      )
      .then(() => {
        setSubmitMessage('Inscrição realizada')
        gtmEvent('/conquer-english', 'conquer-english', 'newsletter')
      })
      .catch(() => {
        setSubmitMessage('Falha na inscrição')
      })
  }

  return (
    <>
      <Style.Footer>
        <div className='container'>
          <div className='logo'>
            <img src='/logo-english-new-brand.png' alt='Conquer' width='158px' height='40px' />
          </div>

          <div className='social'>
            <img
              src='/svg/linkedin.svg'
              alt='Linkedin Conquer'
              onClick={() => linkTo('https://www.linkedin.com/school/escolaconquer/', '_blank')}
              width='31px'
              height='30px'
            />
            <img
              src='/svg/facebook.svg'
              alt='Facebook Conquer'
              onClick={() => linkTo('https://www.facebook.com/escolaconquer/', '_blank')}
              width='31px'
              height='30px'
            />
            <img
              src='/svg/youtube.svg'
              alt='YouTube Conquer'
              onClick={() =>
                linkTo('https://www.youtube.com/channel/UCRs-d5Obk9UG0_fV1d8uhQg/', '_blank')
              }
              width='31px'
              height='30px'
            />
            <img
              src='/svg/instagram.svg'
              alt='Instagram Conquer'
              onClick={() => linkTo('https://www.instagram.com/escolaconquer/', '_blank')}
              width='31px'
              height='30px'
            />
          </div>

          <div className='content'>
            <ul>
              <li>SOBRE A CONQUER</li>
              <li
                onClick={() => linkTo('https://tamojunto.escolaconquer.com.br/hc/pt-br', '_blank')}
              >
                CONTATO
              </li>
              <li
                onClick={() =>
                  linkTo(
                    'https://escolaconquer.com.br/POL%C3%8DTICA_DE_PRIVACIDADE_E_COOKIES-CONQUER.pdf',
                    '_blank',
                  )
                }
              >
                TERMOS DE PRIVACIDADE
              </li>
            </ul>

            <div className='buttons'>
              <Button
                background={theme.color.SECONDARY}
                color={theme.color.BLACK}
                onClick={() => linkTo('https://app.conquerenglish.com.br/', 'self')}
              >
                SOU ALUNO
              </Button>
              <Button
                background={theme.color.SECONDARY}
                color={theme.color.BLACK}
                onClick={() => linkTo(process.env.REACT_APP_HELP_URL, '_blank')}
              >
                CENTRAL DE AJUDA
              </Button>
            </div>

            <div className='newsletter'>
              <label htmlFor='newsletter-input'>
                Hey! Tá esperando o que para assinar nossa newsletter?
              </label>
              <div>
                <input
                  type='email'
                  placeholder='Seu melhor e-mail'
                  id='newsletter-input'
                  onChange={(e) => handleChangeNewsletterEmail(e)}
                />
                <Button
                  outline={theme.color.SECONDARY + ' 1px solid'}
                  background={theme.color.PRIMARY}
                  onClick={handleNewsletterSubmit}
                  color={theme.color.SECONDARY + ' !important'}
                >
                  ENVIAR
                </Button>
                <span>{submitMessage}</span>
              </div>
            </div>
          </div>
        </div>
      </Style.Footer>

      <Style.Lgpd>
        <div className='container'>
          Caso tenha dúvidas sobre privacidade de dados e LGPD, entre em contato com o nosso
          encarregado de dados (LGPD) atráves do e-mail privacidade@escolaconquer.com.br
        </div>
      </Style.Lgpd>
    </>
  )
}

export default Footer
