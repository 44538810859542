import React, { useState } from 'react'

import { Ballon, BallonText, BallonTextContainer, Column, ShowMore, XButton } from './styles'
import ModalDefault from '../ModalDefault'
import AnswersModalContent from '../AnswersModalContent'

export default function BallonMessage(props) {
  const [hideModal, setHideModal] = useState()

  return (
    <>
      <Ballon>
        <BallonTextContainer>
          <Column width='20%'>
            <XButton>X</XButton>
          </Column>
          <Column width='80%'>
            <BallonText>Ops! Alguma coisa pode estar errada.</BallonText>
            <p>Frase correta: {props.message}</p>
            <ShowMore onClick={() => setHideModal(false)} show={props.answers.length > 1}>
              Ver Mais
            </ShowMore>
          </Column>
        </BallonTextContainer>
      </Ballon>
      <ModalDefault
        hideModal={hideModal}
        modalContent={<AnswersModalContent answers={props.answers} />}
        closeModal={(e) => setHideModal(e)}
      />
    </>
  )
}
