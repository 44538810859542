import styled from 'styled-components'

export const Button = styled.button`
  height: 2.375rem;
  padding: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'IBM Plex Sans', 'Prompt', sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  color: ${(props) => props.color || 'var(--white)'};

  background: ${(props) => props.background || 'var(--purple-blue)'};
  border-radius: 0.3rem;
  border: ${(props) => props.outline || 'transparent'};

  @media (max-width: 1080px) {
    font-size: 90%;
  }
  @media (max-width: 720px) {
    font-size: 85%;
  }
`
