import React from 'react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import './index.css'
import { createRoot } from 'react-dom/client'
import App from './App'
import { CaptureConsole } from '@sentry/integrations'
import TagManager from 'react-gtm-module'

if (process.env.REACT_APP_SENTRY === 'production' || process.env.REACT_APP_SENTRY === 'prd') {
  Sentry.init({
    environment: 'production',
    dsn: 'https://9b32dce946154182b76602ff409d7c2a@o510564.ingest.sentry.io/5607001',
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
  })
}

export default Sentry.withProfiler(App)

// Google Tag Manager
// se não funcionar colocar todas props dentro do nó raiz dataLayer
const tagManagerArgs = {
  gtmId: 'GTM-K4SBCQV',
  events: {
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  },
}

TagManager.initialize(tagManagerArgs)

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
