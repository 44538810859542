// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

*{
  scroll-behavior: smooth;
}

.audio{
  margin-top: 25px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

.none{
  display: none!important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #212B36;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #C9DC44;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #C9DC44;
}

.sound-wave{
  display: none;
}

@media screen and (max-width: 900px){
  html, body {
    overflow-x:hidden 
  }

  /**{
    box-sizing: border-box;
  }*/

  .highcharts-root{
    width: 90vw !important;
  }
}

.conquer-phone-container .flag-dropdown {
  margin-top: 10px !important;
}

.react-tel-input .form-control {
  margin-top: 10px !important;
  height: 42px !important;
  width: 100% !important;
}

.conquer-phone-input {
  background: none;
  background-color: #212B36 !important;
  color: white;
  font-size: 17px !important;
  border: 1px solid #898989;
  font-size: 17px;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 10px 20px;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;aACW;AACb;;AAEA,UAAU;AACV;EACE,WAAW;AACb;;AAEA;EACE,uBAAuB;AACzB;;AAEA,UAAU;AACV;EACE,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,mBAAmB;AACrB;;AAEA,oBAAoB;AACpB;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE;EACF;;EAEA;;IAEE;;EAEF;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;EAC3B,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,oCAAoC;EACpC,YAAY;EACZ,0BAA0B;EAC1B,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":["\n\n*{\n  scroll-behavior: smooth;\n}\n\n.audio{\n  margin-top: 25px;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n/* width */\n::-webkit-scrollbar {\n  width: 10px;\n}\n\n.none{\n  display: none!important;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: #212B36;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #C9DC44;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: #C9DC44;\n}\n\n.sound-wave{\n  display: none;\n}\n\n@media screen and (max-width: 900px){\n  html, body {\n    overflow-x:hidden \n  }\n\n  /**{\n    box-sizing: border-box;\n  }*/\n\n  .highcharts-root{\n    width: 90vw !important;\n  }\n}\n\n.conquer-phone-container .flag-dropdown {\n  margin-top: 10px !important;\n}\n\n.react-tel-input .form-control {\n  margin-top: 10px !important;\n  height: 42px !important;\n  width: 100% !important;\n}\n\n.conquer-phone-input {\n  background: none;\n  background-color: #212B36 !important;\n  color: white;\n  font-size: 17px !important;\n  border: 1px solid #898989;\n  font-size: 17px;\n  border-radius: 6px;\n  box-sizing: border-box;\n  padding: 10px 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
