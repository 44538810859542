import { useEffect, useState } from 'react'

export const useUtm = () => {
  const [utm, setUtm] = useState()

  function getUtm() {
    const url = new URLSearchParams(window.location.search)
    const utm = {
      campaign: url.get('utm_campaign'),
      source: url.get('utm_source'),
      medium: url.get('utm_medium'),
      content: url.get('utm_content'),
      term: url.get('utm_term'),
      id: url.get('utm_id'),
    }
    return utm
  }

  useEffect(() => {
    setUtm(getUtm())
  }, [])

  return utm
}
