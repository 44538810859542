import React, { useState } from 'react'
import { Flex, Question, Divider, TitleQustion } from '../../assets/styles/global'
import theme from '../../styles/theme'
import BallonMessage from '../BallonMessage'

export default function TextAnswers(props) {
  const [rightText, setRightText] = useState(null)

  function getWords(text) {
    let initialSplit = text.split(' ')
    let words = []
    for (let str of initialSplit) {
      if (str !== '') {
        words.push(str)
      }
    }
    return words
  }

  function highlight(answer) {
    let rightWords = ''
    let answerWords = getWords(answer)
    let results = []

    for (let i = 0; i < props.question.answers.length; i++) {
      results.push({ text: [], rightCount: 0 })
      rightWords = getWords(props.question.answers[i].text)

      answerWords.forEach((aw) => {
        let lastIndex = null

        for (let j = 0; j < rightWords.length; j++) {
          if (strEquals(aw, rightWords[j])) {
            results[i].text.push(getRightText(aw))
            results[i].rightCount++
            lastIndex = j
            break
          }
        }

        if (lastIndex !== null) {
          rightWords.splice(0, lastIndex + 1)
        } else {
          results[i].text.push(getWrongText(aw))
        }

        results[i].text.push(' ')
      })

      if (results[i].rightCount === props.question.answers.lenght) {
        return results[i].text
      }
    }

    const maxCount = Math.max.apply(
      Math,
      results.map((r) => r.rightCount),
    )
    const rightIndex = results.findIndex((r) => r.rightCount === maxCount)
    if (!props.question.answers.some((a) => a.text === rightText))
      setRightText(props.question.answers[rightIndex].text)

    return results.find((r) => r.rightCount === maxCount).text
  }

  function strEquals(str1, str2) {
    if (!str1 || !str2) return false
    let san1 = sanitize(str1)
    if (!san1) return false
    let san2 = sanitize(str2)
    if (!san2) return false
    return san1.toLowerCase().trim() === san2.toLowerCase().trim()
  }

  function getRightText(text) {
    return <span style={{ color: theme.color.GREEN }}>{text}</span>
  }

  function getWrongText(text) {
    return <span style={{ color: theme.color.RED_FAIL }}>{text}</span>
  }

  function sanitize(str) {
    return str.replaceAll(/[^a-zA-Z]/g, '')
  }

  return (
    <>
      <Flex
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          marginBottom: '40px',
          position: 'relative',
        }}
      >
        <TitleQustion>Write this in English:</TitleQustion>
        <Question>{props.question.text}</Question>
        <Question>{highlight(props.question.userAnswer.text)}</Question>
        <Divider />
        {props.question.userAnswer.right ? (
          ''
        ) : (
          <BallonMessage message={rightText} answers={props.question.answers}></BallonMessage>
        )}
      </Flex>
    </>
  )
}
