import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
	html {
		@media (min-width: 1080px) {
			font-size: 100%;
		}
		@media (max-width: 1080px) {
			font-size: 93.75%;
			margin: 3rem;
		}
		@media (max-width: 720px) {
			font-size: 87.5%;
			margin: 3rem;
		}
	}

	:root {
		--purple-pink: #DA22B4;
		--purple-blue: #5421F5;
		--black: #000;
		--grey-darkest: #141414;
		--grey-dark: #242424;
		--grey-light: #F2F5F9;
		--white: #fff;
		--yellow: #FBFD37;
	}
`

export default GlobalStyle
