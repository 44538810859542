import React from 'react'
import { Link, ModalContentText, ModalContentTitle } from '../../assets/styles/global'

export default function RedoDiagnosticModalContent() {
  function redirectToCac() {
    window.open(process.env.REACT_APP_HELP_URL, '_blank')
  }

  return (
    <>
      <ModalContentTitle>Hey! Você já respondeu nosso teste de nivelamento.</ModalContentTitle>
      <ModalContentText>
        O aluno responde ao seu teste de nivelamento apenas uma vez, caso queira responder novamente
        ou precise rever suas respostas <Link onClick={() => redirectToCac()}>clique aqui</Link> e
        entre em contato com nosso time. Valeu!
      </ModalContentText>
    </>
  )
}
