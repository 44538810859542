import React from 'react'
import {
  Flex,
  Question,
  Answers2,
  Divider,
  AlternativeAnswersContainer,
  AnswerContainer,
  FailCheck,
  SuccessCheck,
  Ballon,
  BallonText,
  CorrectAnswerIndicator,
} from '../../assets/styles/global'

import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ListenAudioAnswers(props) {
  function isSelected(answer) {
    return answer.id === props.question.userAnswer.id
  }

  return (
    <>
      <Flex
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          marginBottom: '40px',
        }}
      >
        <Question>{props.question.text}</Question>
        <Divider />
        <AlternativeAnswersContainer>
          {props.question.answers.map((answer) => (
            <AnswerContainer key={answer.id}>
              <Answers2
                isRight={isSelected(answer) && answer.right}
                selected={isSelected(answer)}
                wasCorrect={!isSelected(answer) && answer.right}
                Position='relative'
              >
                {!isSelected(answer) && answer.right && (
                  <CorrectAnswerIndicator>Essa é a resposta correta</CorrectAnswerIndicator>
                )}
                {answer.text}
              </Answers2>
              {answer.right ? (
                <SuccessCheck>
                  <FontAwesomeIcon icon={faCheckCircle} color={'#2A9235'}></FontAwesomeIcon>
                </SuccessCheck>
              ) : (
                ''
              )}
              {!answer.right && isSelected(answer) ? (
                <FailCheck>
                  <FontAwesomeIcon icon={faTimesCircle} color={'#F95252'}></FontAwesomeIcon>
                </FailCheck>
              ) : (
                ''
              )}
              {answer.right && !isSelected(answer) ? (
                <Ballon hideMobile>
                  <strong>Ops, foi por pouco</strong>
                  <BallonText>Esta é a correta</BallonText>
                </Ballon>
              ) : (
                ''
              )}
            </AnswerContainer>
          ))}
        </AlternativeAnswersContainer>
      </Flex>
    </>
  )
}
