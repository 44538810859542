import React from 'react'
import { CongratulationsText, Text } from './styles'

export default function LevelTransition(props) {
  return (
    <>
      <CongratulationsText>Congratulations!</CongratulationsText>
      <Text>
        Você passou do nível {props.currentLevel}. Agora você vai para as perguntas do nível{' '}
        {props.nextLevel}.
      </Text>

      <Text>Good luck, Conquer!</Text>
    </>
  )
}
