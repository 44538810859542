import styled from 'styled-components'
import theme from '../../styles/theme'

export const Secondary = styled.div`
  color: ${theme.color.SECONDARY};
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
`

export const Contentphrase = styled.div`
  background-color: black;
  padding: 15px;
  font-size: 1.2rem;
  display: block;
  margin-top: 20px;
  font-weight: 300;
  text-align: center;
  color: white;
  & span {
    color: ${theme.color.SECONDARY};
    font-weight: bold;
  }
`
