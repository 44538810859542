import React, { useState, useEffect } from 'react'
import { ReactComponent as IBMLogo } from '../../assets/svg/ibm-logo.svg'
import { Navigate, useParams } from 'react-router-dom'
import { ReactComponent as IconCheck } from '../../assets/svg/check.svg'
import Chart from '../../components/chart'
import {
  ContentHeader,
  Container,
  BtnDefault,
  SmallText,
  TitleHeader,
  ExcerptText,
  IBMLogoBox,
  BreadCrumb,
  Flex,
  BtnText,
  BreadCrumbBox,
  SpanIcon,
  RowForm,
  TableDiagnostic,
} from '../../assets/styles/global'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import { Animated } from 'react-animated-css'
import {
  H2,
  FlexBox,
  ContentContainer,
  ResultadoTexto,
  ResultadoGrafico,
  SpanTitle,
  SpanTitleTranslate,
  SpanExplan,
  ResultadoEscrito,
  TitleTrilha,
  DescritionText,
  Ability,
  AbilityExplan,
  Progress,
  FlexBox2,
  ProgressBar,
  DescritionText2,
  BoxResultado,
  BotaoControle,
  ResultadoNivel,
  Secondary,
  BoxCounter,
  Counter,
} from './styles'
import { useFinish } from '../../context/finish.context'
import TextAnswers from '../../components/TextAnswers'
import ListenAudioAnswers from '../../components/ListenAudioAnswers'
import RecordAudioAnswers from '../../components/RecordAudioAnswers'
import AlternativeAnswers from '../../components/AlternativeAnswers'
import axios from 'axios'
import Lottie from 'react-lottie'
import animationLogo4 from '../../assets/json/loading.json'
import * as Sentry from '@sentry/react'
import theme from '../../styles/theme'

export default function Finish() {
  const [component, setComponent] = useState()
  const [actualQuestion, setActualQuestion] = useState(0)
  const { finishInfo, setFinishInfo } = useFinish() // finishInfo será o retorno do POST com o resultado do diagnóstico.
  const [redirect, setRedirect] = useState(false)
  const [isStudent, setIsStudent] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const { diagnosticId, studentId } = useParams()

  useEffect(() => {
    window.scrollTo(0, 0)

    if (studentId !== undefined) {
      setIsStudent(true)
    }

    if (finishInfo === undefined && !diagnosticId) {
      setRedirect(true)
    }

    if (diagnosticId) {
      getResult()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getResult() {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_HOST}/english-diagnostic/${diagnosticId}/result?sendEmail=false`,
      )
      setFinishInfo(result.data.payload)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  function VerifyQuestionType() {
    switch (finishInfo.answeredQuestions[actualQuestion].questionType) {
      case 'MULTIPLE_CHOICE':
        setComponent(<AlternativeAnswers question={finishInfo.answeredQuestions[actualQuestion]} />)
        break
      case 'AUDIO_LISTENING':
        setComponent(<ListenAudioAnswers question={finishInfo.answeredQuestions[actualQuestion]} />)
        break
      case 'WRITING':
        setComponent(<TextAnswers question={finishInfo.answeredQuestions[actualQuestion]} />)
        break
      case 'AUDIO_RECORDING':
        setComponent(<RecordAudioAnswers question={finishInfo.answeredQuestions[actualQuestion]} />)
        break
      default:
        break
    }
  }

  function isStudentRedirect() {
    let url = 'https://app-homolog.conquerenglish.com.br'
    if (process.env.REACT_APP_SENTRY === 'production' || process.env.REACT_APP_SENTRY === 'prd') {
      url = 'https://app.conquerenglish.com.br'
    }
    if (isStudent) {
      window.location.replace(`${url}/home?origin=diagnostic`)
    }
    window.location.replace(url)
  }

  function NextQuestion() {
    if (
      finishInfo !== undefined &&
      actualQuestion < finishInfo.answeredQuestions.length - 1 &&
      actualQuestion >= 0
    ) {
      setActualQuestion(actualQuestion + 1)
    }
  }

  function PrevQuestion() {
    if (finishInfo !== undefined && actualQuestion > 0) {
      setActualQuestion(actualQuestion - 1)
    }
  }

  useEffect(() => {
    if (finishInfo !== undefined && actualQuestion < finishInfo.answeredQuestions.length) {
      VerifyQuestionType()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualQuestion, finishInfo])

  const [abilities, setAbilities] = useState([
    'Pronunciation',
    'Comprehension',
    'Vocabulary Range',
    'Accuracy',
  ])

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: animationLogo4,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const [series, setSeries] = useState([
    {
      name: 'Resultado',
      data: [100, 100, 100, 100],
      color: theme.color.SECONDARY,
      pointPlacement: 'on',
    },
  ])

  useEffect(() => {
    if (finishInfo) {
      let seriesData = []
      let abilitiesData = []
      for (let ability of finishInfo.abilities) {
        abilitiesData.push(ability.abilityName)
        seriesData.push(parseFloat((ability.totalCorrectPercent * 100).toFixed(2)))
      }

      setSeries([
        {
          name: 'Resultado',
          data: seriesData,
          color: theme.color.SECONDARY,
          pointPlacement: 'on',
        },
      ])
      setAbilities(abilitiesData)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishInfo])

  const Button = () => (
    <Container>
      <Flex
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <BtnDefault
          onClick={() => isStudentRedirect()}
          backgroundColor={theme.color.SECONDARY}
          marginRight='0px'
          textAlign='right'
        >
          <BtnText>Acesse a plataforma</BtnText>
          <IconCheck />
        </BtnDefault>
      </Flex>
    </Container>
  )

  return (
    <>
      {redirect ? <Navigate to='/' /> : ''}
      <ContentHeader>
        <Container>
          <Animated
            animationIn='fadeIn'
            animationOut='fadeIn'
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={true}
          >
            <SmallText>STEP 3</SmallText>
            <TitleHeader>
              <span>Results</span>
            </TitleHeader>
            <ExcerptText>
              Well done!
              <br />
              Muito obrigado pela sua participação no teste de nivelamento Conquer English!
            </ExcerptText>
          </Animated>
        </Container>
        <Animated
          animationIn='fadeIn'
          animationOut='fadeIn'
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={true}
        >
          <BreadCrumbBox>
            <Flex
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <BreadCrumb>YOU</BreadCrumb>
              <BreadCrumb>YOUR SKILLS</BreadCrumb>
              <BreadCrumb active={true}>RESULTS</BreadCrumb>
            </Flex>
          </BreadCrumbBox>
          <IBMLogoBox>
            <IBMLogo />
          </IBMLogoBox>
        </Animated>
      </ContentHeader>
      {isLoading ? (
        <Flex
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TableDiagnostic>
            <RowForm id='row-form-1' style={{ justifyContent: 'center' }}>
              <>
                <div style={{ position: 'relative', marginBottom: '3rem' }}>
                  <div
                    style={{
                      color: theme.color.WHITE,
                      top: '-22px',
                      left: '23px',
                      position: 'absolute',
                    }}
                  >
                    Carregando...
                  </div>
                  <Lottie
                    options={loadingOptions}
                    height={200}
                    isStopped={false}
                    isPaused={false}
                    isClickToPauseDisabled
                  />
                </div>
              </>
            </RowForm>
          </TableDiagnostic>
        </Flex>
      ) : (
        <>
          <Container>
            <H2>Hey! Finalizamos a correção do seu teste. Animado para ver o resultado?</H2>
          </Container>

          <ContentContainer>
            <DescritionText2>
              Confira abaixo o resultado do seu teste de nivelamento. As respostas que foram puladas
              não serão exibidas.
            </DescritionText2>
            <FlexBox height={'200px'} position={'relative'} justifyContent={'center'}>
              <BoxResultado>
                <BoxCounter>
                  <Counter>
                    <Secondary>{actualQuestion + 1}</Secondary>/
                    {finishInfo !== undefined ? finishInfo.answeredQuestions.length : '-'}
                  </Counter>
                </BoxCounter>
                {component}
                <BotaoControle side={'left'} onClick={() => PrevQuestion()}>
                  <SpanIcon marginRight={'5px'}>
                    <FontAwesomeIcon icon={faCaretLeft} />
                  </SpanIcon>{' '}
                  previous
                </BotaoControle>
                <BotaoControle side={'right'} onClick={() => NextQuestion()}>
                  next{' '}
                  <SpanIcon marginLeft={'5px'}>
                    <FontAwesomeIcon icon={faCaretRight} />
                  </SpanIcon>
                </BotaoControle>
              </BoxResultado>
            </FlexBox>

            <FlexBox>
              <ResultadoEscrito>
                <ResultadoTexto>
                  {finishInfo
                    ? finishInfo.abilities.map((ability) => (
                        <div key={ability.name}>
                          <SpanTitle>{ability.abilityName}</SpanTitle>
                          <SpanTitleTranslate>{ability.abilityNameTranslated}</SpanTitleTranslate>
                          <SpanExplan>
                            Percentual de acerto {(ability.totalCorrectPercent * 100).toFixed(2)}%
                          </SpanExplan>
                        </div>
                      ))
                    : ''}
                </ResultadoTexto>
              </ResultadoEscrito>

              <ResultadoGrafico>
                <Chart categories={abilities} series={series} />
              </ResultadoGrafico>
            </FlexBox>

            <TitleTrilha>
              Você iniciará no nível {finishInfo ? finishInfo.classificationBr : ''}!
            </TitleTrilha>

            <DescritionText>
              Seu ponto forte é: {finishInfo ? finishInfo.strongPoint.abilityName : ''}
            </DescritionText>
            <FlexBox2>
              <FlexBox2>
                <Ability>{finishInfo ? finishInfo.strongPoint.abilityName : ''}</Ability>
              </FlexBox2>
              <FlexBox2 mLeft={'auto'}>
                <ProgressBar>
                  <Progress
                    width={finishInfo ? finishInfo.strongPoint.totalCorrectPercent * 100 : '0'}
                  />
                </ProgressBar>
                <ResultadoNivel>
                  <Secondary>
                    {finishInfo ? finishInfo.strongPoint.totalCorrectQuestions : ''}
                  </Secondary>
                  /{finishInfo ? finishInfo.strongPoint.totalQuestions : ''}
                </ResultadoNivel>
              </FlexBox2>
            </FlexBox2>

            <AbilityExplan>{finishInfo ? finishInfo.strongPoint.description : ''}</AbilityExplan>

            <DescritionText>
              O ponto que iniciaremos a trabalhar é:{' '}
              {finishInfo ? finishInfo.weakPoint.abilityName : ''}
            </DescritionText>

            <FlexBox2>
              <FlexBox2>
                <Ability>{finishInfo ? finishInfo.weakPoint.abilityName : ''}</Ability>
              </FlexBox2>
              <FlexBox2 mLeft={'auto'}>
                <ProgressBar>
                  <Progress
                    width={finishInfo ? finishInfo.weakPoint.totalCorrectPercent * 100 : '0'}
                  />
                </ProgressBar>
                <ResultadoNivel>
                  <Secondary>
                    {finishInfo ? finishInfo.weakPoint.totalCorrectQuestions : ''}
                  </Secondary>
                  /{finishInfo ? finishInfo.weakPoint.totalQuestions : ''}
                </ResultadoNivel>
              </FlexBox2>
            </FlexBox2>

            <AbilityExplan>{finishInfo ? finishInfo.weakPoint.description : ''}</AbilityExplan>

            <Button />
          </ContentContainer>
        </>
      )}
    </>
  )
}
