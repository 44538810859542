import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie'
import {
  ContentHeader,
  Container,
  SmallText,
  TitleHeader,
  ExcerptText,
  ModalContentText,
  ModalContentTitle,
  IBMLogoBox,
  BreadCrumb,
  Flex,
  BreadCrumbBox,
  TableDiagnostic,
  BtnNext,
  Counter,
  Highlight,
  CounterBox,
  BtnSkip,
} from '../../assets/styles/global'
import { ReactComponent as IconNext } from '../../assets/svg/button-next.svg'
import { Animated } from 'react-animated-css'
import { ReactComponent as IBMLogo } from '../../assets/svg/ibm-logo.svg'
import RecordAudioQuestion from '../../components/RecordAudioQuestion'
import ListenAudioQuestion from '../../components/ListenAudioQuestion'
import TextQuestion from '../../components/TextQuestion'
import AlternativeQuestion from '../../components/AlternativeQuestion'
import ModalDefault from '../../components/ModalDefault'
import axios from 'axios'
import { Navigate, useParams } from 'react-router-dom'
import LevelTransition from '../../components/LevelTransition'
import { getPersistedState } from 'persisted-state'
import animationQuestions from '../../assets/json/loading_questions.json'
import LoadingQuestions from '../../components/loadingQuestions'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useFinish } from '../../context/finish.context'
import { useBlockerModal } from '../../context/blockerModal.context'
import validator from 'validator'
import theme from '../../styles/theme'

let postPayload = {}

export default function Skills() {
  const [redirect, setRedirect] = useState(false)
  const [component, setComponent] = useState()
  const [isDisabled, setIsDisabled] = useState(true)
  const [buttonText, setButtonText] = useState('next')
  const [renderTransitionPage, setRenderTransitionPage] = useState(false)
  const [counterQuestions, setCounterQuestions] = useState({ actual: '-', total: '-' })
  const [loadingQuestions, setLoadingQuestions] = useState(true)
  const [loadingResults, setLoadingResults] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState(false)
  const [percentageLoading, setPercentageLoading] = useState(0)
  const { setFinishInfo } = useFinish()
  const [showCounterBox, setShowCounterBox] = useState(true)
  const [buttonColor, setButtonColor] = useState('#505050')
  const [buttonSkipColor, setButtonSkipColor] = useState('#A2A2A2')
  const [buttonSkipHide, setButtonSkipHide] = useState(false)
  const [hideModal, setHideModal] = useState(true)
  const [firstTextQuestion, setFirstTextQuestion] = useState(true)
  const [questionType, setQuestionType] = useState('')
  const { setBlockerModal } = useBlockerModal()
  const [intervalID, setIntervalID] = useState()
  const [clearLoadingInterval, setClearLoadingInterval] = useState(false)

  const { diagnosticId, studentId } = useParams()

  const loadingQuestionOptions = {
    loop: true,
    autoplay: true,
    animationData: animationQuestions,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  function isStudent() {
    return studentId
  }

  async function sendLead() {
    let newPayload = JSON.parse(getPersistedState('payload'))
    newPayload.campaignId = process.env.REACT_APP_LEAD_CAMPAIGN_DIAGNOSTIC_FINISHED_ID
    newPayload.lgpdChecks = [
      `${process.env.REACT_APP_LEAD_CAMPAIGN_LGPD_CHECKS_DIAGNOSTIC_FINISHED_ID}`,
    ]
    await axios.post(`${process.env.REACT_APP_BACKEND_MKT}/lead-api/lead`, newPayload)
  }

  async function getResult() {
    setLoadingQuestions(false)
    setLoadingResults(true)
    setButtonLoading(false)
    setButtonSkipHide(true)
    setShowCounterBox(false)

    try {
      const result = await axios.get(
        `${process.env.REACT_APP_HOST}/english-diagnostic/${diagnosticId}/result?sendEmail=true`,
      )

      if (!isStudent()) {
        sendLead()
      }

      setLoadingResults(false)

      setTimeout(() => {
        if (result.data.payload.diagnosticFinished === true) {
          setFinishInfo(result.data.payload)
          setClearLoadingInterval(true)
          setRedirect(true)
        } else {
          setRenderTransitionPage(true)
        }
        setShowCounterBox(false)
      }, 9000)
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setClearLoadingInterval(true)
        let modalConfig = {
          status: true,
          text1: 'Seu diagnóstico ainda está sendo processado,',
          text2: 'enviaremos o resultado no seu email :)',
          type: 'long',
        }
        setBlockerModal(modalConfig)
      }
    }
  }

  async function nextQuestion() {
    setIsDisabled(true)
    setPercentageLoading(66)

    // previne o request caso o diagnosticId seja inválido
    if (!diagnosticId || (diagnosticId && !validator.isUUID(diagnosticId))) {
      console.error('invalid diagnosticId')
      return
    }

    const question = await axios.get(
      `${process.env.REACT_APP_HOST}/english-diagnostic/${diagnosticId}/next-question`,
    )
    if (!question) {
      console.error('erro ao buscar pergunta')
      return
    }

    setCurrentQuestion(question.data.payload.question)

    setTimeout(() => {
      setPercentageLoading(100)

      if (question.data.payload.question === null) {
        return getResult()
      }

      setCounterQuestions({
        actual: question.data.payload.totalAnswers,
        total: question.data.payload.totalQuestions,
      })
      VerifyQuestionType(question.data.payload.question)
      setTimeout(() => {
        setLoadingQuestions(false)
        setClearLoadingInterval(true)
        setPercentageLoading(0)
        setTimeout(() => {
          setButtonLoading(false)
          setButtonColor('#505050')
          setButtonSkipColor('#A2A2A2')
          setButtonSkipHide(false)
          setShowCounterBox(true)
        }, 500)
      }, 500)
    }, 300)
  }

  function handleAnswers(e) {
    setButtonColor(theme.color.SECONDARY)
    setButtonSkipColor('#A2A2A2')

    for (let i = 0; i < postPayload.length; i++) {
      if (postPayload[i].questionId === e.questionId) {
        postPayload.splice(i, 1)
      }
    }

    postPayload = {
      questionId: e.questionId,
      answer: e.answer,
      diagnosticId: diagnosticId,
      skipped: false,
    }
  }

  async function saveAnswer() {
    setButtonColor('transparent')
    setButtonLoading(true)
    setIsDisabled(true)

    setTimeout(() => {
      setPercentageLoading(20)
    }, 300)

    const saveAnswerRequest = await axios.post(
      `${process.env.REACT_APP_HOST}/english-diagnostic/answer`,
      postPayload,
    )

    if (saveAnswerRequest.status !== 201) {
      return console.error('erro ao enviar pergunta')
    }
    // @TODO check for more errors, like pronunciation server unavailable

    setPercentageLoading(37)
    nextQuestion()

    postPayload = []
  }

  async function watchLoadingQuestionsProgress() {
    setClearLoadingInterval(false)
    let secondsElapsed = 0
    let id = setInterval(() => {
      secondsElapsed++
      if (secondsElapsed >= 40) {
        setBlockerModal({ status: true, text: 'Ops! Algo deu errado, tente novamente.' })
        clearInterval(id)
      }
    }, 1000)
    setIntervalID(id)
  }

  async function handleNext() {
    if (isDisabled || buttonLoading) return

    if (firstTextQuestion && questionType === 'WRITING') {
      setHideModal(false)
      return
    }

    setLoadingQuestions(true)
    await watchLoadingQuestionsProgress()
    setButtonSkipColor('transparent')
    setButtonSkipHide(true)

    if (renderTransitionPage) {
      nextQuestion()
      setRenderTransitionPage(false)
      setButtonSkipHide(false)
      setButtonColor('#505050')
      setButtonSkipColor('#A2A2A2')
    } else {
      saveAnswer()
    }
  }

  function VerifyQuestionType(data) {
    setQuestionType(data.questionType)

    switch (data.questionType) {
      case 'MULTIPLE_CHOICE':
        setComponent(
          <AlternativeQuestion
            question={data}
            callBack={(e) => handleAnswers(e)}
            changeNext={(e) => setIsDisabled(e)}
          />,
        )
        break
      case 'AUDIO_LISTENING':
        setComponent(
          <ListenAudioQuestion
            question={data}
            callBack={(e) => handleAnswers(e)}
            changeNext={(e) => setIsDisabled(e)}
          />,
        )
        break
      case 'WRITING':
        setComponent(
          <TextQuestion
            question={data}
            callBack={(e) => handleAnswers(e)}
            changeNext={(e) => setIsDisabled(e)}
          />,
        )
        break
      case 'AUDIO_RECORDING':
        setComponent(
          <RecordAudioQuestion
            question={data}
            callBack={(e) => handleAnswers(e)}
            changeNext={(e) => setIsDisabled(e)}
            setButtonColor={() => setButtonColor()}
          />,
        )
        break
      default:
        break
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    nextQuestion()
  }, [])

  useEffect(() => {
    if (clearLoadingInterval && intervalID) {
      clearInterval(intervalID)
    }
  }, [clearLoadingInterval, intervalID])

  useEffect(() => {
    if (loadingResults) {
      setComponent(<LoadingQuestions></LoadingQuestions>)
    }
  }, [loadingResults])

  useEffect(() => {
    if (renderTransitionPage) {
      setComponent(
        <LevelTransition currentLevel='Básico' nextLevel='Intermediário'></LevelTransition>,
      )
      setButtonText('continue')
      setIsDisabled(false)
      setButtonColor(theme.color.SECONDARY)
    } else {
      setButtonText('next')
    }
  }, [renderTransitionPage])

  function skipAnswer() {
    if (buttonLoading) return

    setButtonColor('transparent')
    setButtonSkipColor('transparent')
    setShowCounterBox(false)
    setLoadingQuestions(true)

    postPayload = { questionId: currentQuestion.id, diagnosticId: diagnosticId, skipped: true }

    saveAnswer()
  }

  function handleHideModal(e) {
    if (firstTextQuestion) {
      setFirstTextQuestion(false)
    }
    setHideModal(e)
  }

  return (
    <>
      {redirect && studentId && diagnosticId ? (
        <Navigate to={`/student/${studentId}/diagnostic/${diagnosticId}/result`} />
      ) : (
        ''
      )}
      {redirect && diagnosticId ? <Navigate to={`/diagnostic/${diagnosticId}/result`} /> : ''}

      <ContentHeader>
        <Container>
          <Animated
            animationIn='fadeIn'
            animationOut='fadeIn'
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={true}
          >
            <SmallText>STEP 2</SmallText>
            <TitleHeader>
              What are your<span> skills?</span>
            </TitleHeader>
            <ExcerptText>
              Agora focaremos no nivelamento do seu inglês, seu perfil e respostas serão analisados
              pelo Watson, o robô de Inteligência Artificial da IBM. E ao final, teremos o curso
              ideal para o seu nível de inglês!
            </ExcerptText>
          </Animated>
        </Container>
        <Animated
          animationIn='fadeIn'
          animationOut='fadeIn'
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={true}
        >
          <BreadCrumbBox>
            <Flex
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <BreadCrumb>YOU</BreadCrumb>
              <BreadCrumb active={true}>YOUR SKILLS</BreadCrumb>
              <BreadCrumb>RESULTS</BreadCrumb>
            </Flex>
          </BreadCrumbBox>
          <IBMLogoBox>
            <IBMLogo />
          </IBMLogoBox>
        </Animated>
      </ContentHeader>

      <Container>
        <Flex
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TableDiagnostic>
            {loadingQuestions ? (
              <SkeletonTheme color='#3a3a3a' highlightColor='#505050' style={{ margin: '0 auto' }}>
                <p style={{ textAlign: 'center' }}>
                  <Skeleton width={'60%'} height={50} />
                </p>
                <p style={{ textAlign: 'center' }}>
                  <Skeleton width={120} height={120} circle={true} />
                </p>
                <p style={{ textAlign: 'center' }}>
                  <Skeleton width={'70%'} height={40} count={4} style={{ margin: '8px 0px' }} />
                </p>
              </SkeletonTheme>
            ) : (
              component
            )}
          </TableDiagnostic>
          <ModalDefault
            modalContent={
              <>
                <ModalContentTitle>Hey! Você tem certeza disso?</ModalContentTitle>
                <ModalContentText>
                  Uma dica: A inteligência artificial é treinada para não aceitar erros de digitação
                  que podem implicar em erros ortográficos. Se você estiver em um celular ou tablet,
                  tome cuidado redobrado com o corretor automático.
                </ModalContentText>
                <ModalContentText>Sempre revise antes de enviar (:</ModalContentText>
              </>
            }
            hideModal={hideModal}
            closeModal={handleHideModal}
            okButton
          />
        </Flex>
        <Flex
          style={{
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
        >
          <BtnSkip
            onClick={() => skipAnswer()}
            hide={buttonSkipHide}
            loading={buttonLoading}
            percentage={percentageLoading}
            backgroundColor={buttonSkipColor}
          >
            {buttonLoading ? (
              <Lottie
                options={loadingQuestionOptions}
                height={50}
                width={50}
                isStopped={false}
                isPaused={false}
                isClickToPauseDisabled
              />
            ) : (
              <>
                não sei
                <IconNext />
              </>
            )}
          </BtnSkip>
          <CounterBox show={showCounterBox}>
            <Counter>
              <Highlight>
                {counterQuestions.actual > -1 ? counterQuestions.actual + 1 : '-'}
              </Highlight>
              /{counterQuestions.total}
            </Counter>
            <BtnNext
              onClick={() => handleNext()}
              loading={buttonLoading}
              percentage={percentageLoading}
              backgroundColor={buttonColor}
            >
              {buttonLoading ? (
                <Lottie
                  options={loadingQuestionOptions}
                  height={50}
                  width={50}
                  isStopped={false}
                  isPaused={false}
                  isClickToPauseDisabled
                />
              ) : (
                <>
                  {buttonText}
                  <IconNext />
                </>
              )}
            </BtnNext>
          </CounterBox>
        </Flex>
      </Container>
    </>
  )
}
