/* eslint-disable prettier/prettier */
import styled from 'styled-components'
import theme from '../../styles/theme'

export const Opacity = styled.div`
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  align-items: center;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`

export const Modal = styled.div`
  width: 500px;
  border: 2px solid ${theme.color.PRIMARY};
  border-radius: 24px;
  display: flow-root;
  background-color: #0c0c0c;
  min-height: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* -webkit-box-shadow: 16px 16px 0px -2px #000000, 16px 16px 0px 0px ${theme.color.PRIMARY}; */
  box-shadow: 16px 16px 0px -2px #000000, 16px 16px 0px 0px ${theme.color.PRIMARY};

  @media (max-width: 575px) {
    width: 72%;
    padding: 20px 20px 40px 20px;
  }

  @media (min-width: 576px) {
    width: 400px;
    padding: 20px 40px 40px 40px;
  }

  @media (min-width: 768px) {
    width: 500px;
    padding: 40px 80px 80px 80px;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
`

export const ModalTitle = styled.h1`
  font-size: 2rem;
  color: ${theme.color.SECONDARY};
  margin-left: auto;
  margin-right: auto;
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-weight: 500;
  font-size: 1.8rem;
  color: white;
  cursor: pointer;
  align-items: center;
  align-self: flex-start;
  display: flex;
  position: absolute;
  right: 40px;

  @media (max-width: 575px) {
    right: 20px;
    font-size: 1.5rem;
  }

  @media (min-width: 576px) {
    right: 20px;
  }

  @media (min-width: 768px) {
    right: 40px;
  }
`

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
  max-height: 500px;
  overflow: auto;
  flex-direction: column;
`

export const OkButton = styled.button`
  background-color: ${theme.color.PRIMARY};
  color: ${theme.color.WHITE};
  border-radius: 53px;
  border: none;
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px 25px;
  font-size: 18px;
  text-align: center;
  position: absolute;
  outline: none;
  bottom: 40px;
  right: 40px;

  &:focus {
    outline: none;
  }

  @media (max-width: 767px) {
    bottom: 20px;
    right: 20px;
  }
`
