import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie'
import animationData from '../../assets/json/spinner.json'
import { Container, Flex } from '../../assets/styles/global'
import { Contentphrase } from './styles'

export default function LoadingQuestions() {
  const [Phrase, setPhrase] = useState('Definindo <span>destino</span>...')

  const phrases = [
    'Traçando <span>plano de voo</span>...',
    '<span>Aguardando</span> permissão da torre...',
    'Decolando...',
  ]

  let counter = 0

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    let changePhrase
    changePhrase = setInterval(() => {
      setPhrase(phrases[counter])
      if (counter + 1 === phrases.length) {
        clearInterval(changePhrase)
      } else {
        counter++
      }
    }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Flex
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Lottie options={defaultOptions} height={120} width={120} />
        <Contentphrase dangerouslySetInnerHTML={{ __html: Phrase }} />
      </Flex>
    </Container>
  )
}
