/* eslint-disable prettier/prettier */
import styled, { keyframes } from 'styled-components'
import theme from '../../styles/theme'

export const ContentHeader = styled.div`
  background-color: ${theme.color.MAIN_BG};
  border-radius: 0px 0px 32px 32px;
  padding: 100px 0px;
  width: 100%;
  position: relative;
  @media (max-width: 900px) {
    max-width: 100vw;
  }
`

export const BreadCrumb = styled.div`
  text-transform: uppercase;
  width: 350px;
  text-align: center;
  font-size: 20px;
  color: ${(props) => (props.active ? theme.color.SECONDARY : '#505050')};
  user-select: none;
  position: relative;
  &:after {
    content: ${(props) => (props.active ? '"\'\'"' : '')};
    position: absolute;
    background-color: ${theme.color.SECONDARY};
    height: 4px;
    width: 55%;
    left: 50%;
    bottom: -20px;
    transform: translateX(-50%);
  }
  @media (max-width: 900px) {
    display: ${(props) => (props.active ? 'block' : 'none')};
  }
`

export const IBMLogoBox = styled.div`
  position: absolute;
  right: 32px;
  bottom: 32px;
  @media (max-width: 900px) {
    display: none;
  }
`

export const ExcerptText = styled.p`
  color: white;
  font-size: 20px;
  line-height: 35px;
  font-weight: 300;
  padding-right: 40px;
  @media (max-width: 900px) {
    padding: 0px;
    font-size: 17px;
    line-height: 29px;
  }
`

export const ModalContentTitle = styled.p`
  color: white;
  font-size: 22px;
  line-height: 35px;
  font-weight: 200;
  display: block;
  align-self: start;
  margin-top: 0px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

export const ModalContentText = styled.p`
  color: white;
  font-size: 18px;
  line-height: 35px;
  font-weight: 200;
  display: block;
  align-self: start;
  margin-top: 0px;
  margin-bottom: 0px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`

export const TitleHeader = styled.h1`
  font-size: 54px;
  color: white;
  margin-bottom: 14px;
  font-weight: bold;
  margin-top: 0;
  & span {
    color: ${theme.color.SECONDARY};
  }
  @media (max-width: 900px) {
    font-size: 40px;
  }
`

export const TextBold = styled.div`
  color: ${theme.color.WHITE};
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 8px;
  text-align: justify;
  & .markdown {
    color: ${theme.color.BLACK};
    background-color: ${theme.color.SECONDARY};
  }
  & .bold {
    font-weight: 600;
  }
`

export const SmallText = styled.div`
  color: ${theme.color.GREY300};
  text-transform: uppercase;
  font-size: 18x;
  font-weight: 300;
  margin-bottom: 8px;
`
export const SmallTextBold = styled.div`
  color: ${theme.color.GREY300};
  text-transform: uppercase;
  font-size: 18x;
  font-weight: bold;
  margin-bottom: 8px;
`

export const BreadCrumbBox = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
`

export const TitleQustion = styled.div`
  text-align: center;
  width: 100%;
  color: ${theme.color.GREY300};
  font-size: 26px;
`

export const Flex = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: ${(props) => props.AlignItems};
  justify-content: ${(props) => props.JustifyContent};
  flex-direction: ${(props) => props.FlexDirection};
  position: ${(props) => props.Position || ''};
  margin-bottom: ${(props) => props.MarginBottom || '0px'};

  &.bg_secondary {
    background-color: ${theme.color.SECONDARY_BG};
  }
`

export const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: ${(props) => props.AlignItems};
  justify-content: ${(props) => props.JustifyContent};
  flex-direction: ${(props) => props.FlexDirection};
  position: ${(props) => props.Position || ''};
`

export const Container = styled.div`
  width: calc(100% - 30px);
  padding-right: 15px;
  position: relative;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: ${(props) => props.backgroundColor || ''};
  height: ${(props) => (props.heightFull ? '100%' : 'auto')};
  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1336px) {
    max-width: 1310px;
  }
`
export const TableDiagnostic = styled.div`
  width: 700px;
  margin-top: 68px;
  min-height: 500px;

  @media (max-width: 900px) {
    padding: 0px 15px;
  }
`

export const SubTitleDiagnostic = styled.h3`
  color: ${theme.color.GREY500};
  margin-top: ${(props) => props.mt};
  font-size: 17px;
  text-align: center;
  font-weight: bold;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: ${theme.color.GREY550};
  }
`

export const Label = styled.label``
export const LabelRadio = styled.label`
  font-size: 17px;
  color: #898989;
`

export const Input = styled.input`
  width: 100%;
  color: white;
`

export const RowForm = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Select = styled.select`
  moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 1.25em;
  appearance: none;
  position: relative;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='15px'%3E%3Ctext x='0' y='10' fill='gray'%3E%E2%96%BE%3C/text%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.5em 1em;
  background-position: right center;
  background-clip: border-box;
  -moz-background-clip: border-box;
  -webkit-background-clip: border-box;

  &-ms-expand {
    display: none;
  }
`
export const Option = styled.option`
  -webkit-appearance: none;
`

export const LabelCheckbox = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 15px;
`

export const Checkmark = styled.span`
  position: absolute;
  top: 5px;
  border: 1px solid ${theme.color.GREY500};
  left: 9px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`

export const InputDiagnostic = styled.div`
  width: ${(props) => props.Lg - 2}%;
  position: relative;
  margin: ${(props) => (props.margin ? props.margin : '30px 1% 0px 1%')};
  margin-top: ${(props) => props.mt};

  & ${Label} {
    color: white;
    font-weight: bold;
  }

  & ${Input}[type="text"], & ${Input}[type="email"] {
    background-color: #212b36;
    border: 2px solid ${theme.color.GREY100};
    font-size: 17px;
    border-radius: 6px;
    margin-top: 10px;
    box-sizing: border-box;
    padding: 10px 18px;
  }

  & ${Input}[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ ${Checkmark} {
      background-color: ${theme.color.SECONDARY} !important;
      &:after {
        display: block;
      }
    }
    &:checked ~ ${Checkmark} {
      top: 5px;
      left: 9px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
  }

  & ${Select} {
    background-color: #323232;
    border: 1px solid ${theme.color.GREY300};
    font-size: 17px;
    border-radius: 6px;
    margin-top: 10px;
    box-sizing: border-box;
    padding: 10px 18px;
    color: white;
    width: 100%;
    &${Option} {
      background-color: ${theme.color.GREY600};
      border: 1px solid ${theme.color.GREY300};
      font-size: 17px;
      border-radius: 6px;
      margin-top: 10px;
      box-sizing: border-box;
      color: white;
      padding: 10px 18px;
    }
  }
`

export const ValidationError = styled.p`
  color: ${theme.color.RED_FAIL};
  font-weight: bold;
  padding: 0;
  margin: 7px 0px -14px 0px;
  display: ${(props) => (props.display ? 'inline' : 'none')};
`

const sizing = keyframes`
  from {
    width: 130px;
    height: 50px;
  }

  to {
    width: 80px;
    height: 80px;
  }
`

const unsizing = keyframes`
  from {
    width: 80px;
    height: 80px;
  }

  to {
    width: 130px;
    height: 50px;
  }
`

export const BtnDefault = styled.div`
  user-select: none;
  position: relative;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : theme.color.SECONDARY + ' !important'};
  margin: 30px;
  border-radius: 53px;
  width: max-content;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '100px')};
  padding: 0px 15px;
  font-size: 18px;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  & > div {
    overflow: unset !important;
  }
  & svg path {
    stroke: black;
  }
  & .fa-1x {
    font-size: 1.3em !important;
  }
  @media (max-width: 575px) {
    border: none;
  }

  @media (max-width: 900px) {
    margin-right: 0px;
    margin-top: 10px;
  }
`

export const BtnText = styled.span`
  & + svg {
    margin: 0px 0px 0px 10px;
  }

  svg + & {
    margin: 0px 0px 0px 10px;
  }
`

export const BtnNext = styled.div`
  user-select: none;
  position: relative;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : theme.color.SECONDARY + ' !important'};
  margin: 30px;
  border-radius: ${(props) => (props.loading ? '50%' : '53px')};
  width: ${(props) => (props.loading ? '80px' : '130px')};
  height: ${(props) => (props.loading ? '80px' : '50px')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '100px')};
  font-size: 12px;
  font-weight: bold;
  animation: ${(props) => (props.loading ? sizing : unsizing)},
    ${(props) => (props.loading ? '1s' : '.4s')};
  & > div {
    overflow: unset !important;
  }
  & svg {
    margin: ${(props) => (props.loading ? '3px 0px 0px 0px' : '0px 0px 0px 10px')};
  }
  & svg path {
    stroke: black;
  }
  &:before {
    content: '';
    background-color: ${theme.color.SECONDARY};
    position: absolute;
    bottom: 0;
    left: 0;
    height: ${(props) => props.percentage}%;
    width: 100%;
    transition: 0.4s;
    opacity: ${(props) => (props.loading ? '1' : '0')};
    z-index: -1;
    display: ${(props) => (props.loading ? 'block' : 'none')};
  }
  &:after {
    content: '';
    background-color: ${theme.color.GREY550};
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -2;
    display: ${(props) => (props.loading ? 'block' : 'none')};
  }

  @media (max-width: 1080px) {
    font-size: 90%;
    margin-right: 50px;
    margin-top: 10px;
  }
  @media (max-width: 720px) {
    font-size: 85%;
    margin-right: 50px;
  }
  @media (max-width: 375px) {
    border: ${(props) => (props.loading ? '30px solid #252525' : 'none')};
    margin-right: 5px;
  }
`

export const BtnSkip = styled.div`
  user-select: none;
  position: relative;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : theme.color.SECONDARY + ' !important'};
  margin: 30px;
  border-radius: ${(props) => (props.loading ? '50%' : '53px')};
  width: ${(props) => (props.loading ? '80px' : '150px')};
  height: ${(props) => (props.loading ? '80px' : '50px')};
  display: flex;
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 100px;
  font-size: 18px;
  animation: ${(props) => (props.loading ? sizing : unsizing)},
    ${(props) => (props.loading ? '1s' : '.4s')};
  & > div {
    overflow: unset !important;
  }
  & svg {
    margin: ${(props) => (props.loading ? '3px 0px 0px 0px' : '0px 10px 0px 10px')};
  }
  & svg path {
    stroke: black;
  }
  &:before {
    content: '';
    background-color: ${theme.color.SECONDARY};
    position: absolute;
    bottom: 0;
    left: 0;
    height: ${(props) => props.percentage}%;
    width: 100%;
    transition: 0.4s;
    opacity: ${(props) => (props.loading ? '1' : '0')};
    z-index: -1;
    display: ${(props) => (props.loading ? 'block' : 'none')};
  }
  &:after {
    content: '';
    background-color: ${theme.color.GREY550};
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -2;
    display: ${(props) => (props.loading ? 'block' : 'none')};
  }

  @media (max-width: 900px) {
    margin-left: 0px;
    margin-top: 10px;
  }

  @media (max-width: 375px) {
    border: ${(props) => (props.loading ? '30px solid #252525' : 'none')};
  }
`

export const BtnPlay = styled.div`
  user-select: none;
  position: relative;
  background-color: ${theme.color.SECONDARY} !important;
  border-radius: 53px;
  width: max-content;
  height: max-content;
  min-height: 35px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: black;
  margin-right: 10px;
  font-size: 18px;
  padding-left: 10px;
  transition: width 0.1s ease;
  webkit-transition: width 1s linear 0.5s;
  & > div {
    overflow: unset !important;
  }
  & svg {
    margin-right: 0px !important;
    margin-left: 0px;
  }
  & svg path {
    stroke: black;
  }

  @media (max-width: 767px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`

export const Question = styled.div`
  color: ${(props) => (props.color ? props.color : 'white')};
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  margin-top: 20px;
`

export const Message = styled.div`
  background-color: ${theme.color.PRIMARY};
  padding: 16px;
  text-align: center;
  color: ${theme.color.WHITE};
  font-weight: bold;
  font-size: 16px;
  position: relative;
  margin-top: 35px;
  line-height: 24px;
  & svg {
    position: absolute;
    left: 50%;
    top: -18px;
    transform: translateX(-50%);
  }
`

export const MessageError = styled(Message)`
  background-color: ${theme.color.RED_FAIL};
  color: white;
  & svg > path {
    fill: ${theme.color.RED_FAIL};
  }
`

const AnimationRecorder = keyframes`
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgb(252 21 21);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
}
`

export const RecordButton = styled.div`
  background-color: ${(props) =>
    props.recordDisabled
      ? theme.color.GREY500
      : props.recording === 'recording'
      ? theme.color.WHITE
      : theme.color.YELLOW};
  width: 110px;
  height: 110px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 50px;
  animation: ${(props) => (props.Recording === 'recording' ? AnimationRecorder : '')} 2s infinite;
`

export const Divider = styled.div`
  width: 100%;
  height: 30px;
`

export const Answers = styled.div`
  background-color: ${(props) =>
    props.selected
      ? theme.color.SECONDARY
      : props.isRight
      ? theme.color.GREEN
      : theme.color.PRIMARY};
  color: ${(props) => (props.selected ? 'black' : 'white')};
  font-size: 20px;
  text-align: center;
  padding: 13px 0px;
  width: 70%;
  border-radius: 6px;
  margin: 10px 0px;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background-color: ${(props) =>
      props.isRight
        ? theme.color.GREEN
        : props.isAnswers
        ? theme.color.GREY300
        : theme.color.SECONDARY};
    color: ${(props) => (props.isAnswers ? 'white' : 'black')};
    transition: 0.4s;
  }
`

export const Answers2 = styled.div`
  background-color: ${(props) =>
    props.Selected
      ? props.isRight
        ? theme.color.GREEN
        : theme.color.RED_FAIL
      : theme.color.GREY550};
  color: white;
  font-size: 20px;
  text-align: center;
  padding: 13px 8px;
  border-radius: 6px;
  margin: 10px 0px;
  cursor: pointer;
  transition: 0.4s;
  position: ${(props) => props.Position || ''};
  width: 100%;
  white-space: pre-wrap;

  &:hover {
    background-color: ${(props) => (props.isRight ? theme.color.GREEN : theme.color.GREY500)};
    color: white;
    transition: 0.4s;
  }

  @media (max-width: 767px) {
    border: ${(props) => (props.wasCorrect ? '3px solid ' + theme.color.GREEN : '')};
  }

  @media (min-width: 30px) {
    max-width: 200px;
    min-width: 200px;
  }

  @media (min-width: 450px) {
    max-width: 300px;
    min-width: 300px;
  }

  @media (min-width: 576px) {
    max-width: 400px;
    min-width: 300px;
  }

  @media (min-width: 768px) {
    max-width: 400px;
    min-width: 300px;
  }

  @media (min-width: 992px) {
    max-width: 500px;
    min-width: 300px;
  }
`

export const AlternativeAnswersContainer = styled.div`
  min-width: min-content;
`

export const AnswerContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
`

export const FailCheck = styled.div`
  margin-left: 15px;
  position: absolute;
  right: -30px;
  height: 100%;
  display: flex;
  align-items: center;
`

export const SuccessCheck = styled.div`
  margin-left: 15px;
  position: absolute;
  right: -30px;
  height: 100%;
  display: flex;
  align-items: center;
`

export const Ballon = styled.div`
  position: absolute;
  background-color: ${theme.color.WHITE};
  padding: 15px;
  border-radius: 6px;
  width: 144px;
  right: -230px;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 16px solid ${theme.color.WHITE};
    position: absolute;
    left: -15px;
    top: 21px;
  }

  @media (max-width: 767px) {
    ${(props) => props.hideMobile && 'display: none;'}
    left: calc(50% - 86px);
    top: -78px;

    &:after {
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-top: 16px solid ${theme.color.WHITE};
      left: 77px;
      top: 65px;
    }
  }
`

export const BallonText = styled.p`
  margin: 0;
`

export const TextAreaWithRows = styled.textarea`
  width: 70%;
  line-height: 5ch;
  background-image: linear-gradient(#ffffff00, transparent calc(5ch - 1px), #898989 0px);
  background-size: 100% 5ch;
  background-color: transparent;
  height: 20ch;
  outline: none;
  resize: none;
  color: white;
  border: none;
  font-size: 18px;
`

export const SpanIcon = styled.span`
  margin-top: ${(props) => props.marginTop || ''};
  margin-right: ${(props) => props.marginRight || ''};
  margin-bottom: ${(props) => props.marginBottom || ''};
  margin-left: ${(props) => props.marginLeft || ''};
`

export const Highlight = styled.span``

export const Counter = styled.div`
  font-size: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: ${theme.color.GREY300};
  margin-right: 100px;
  margin-bottom: 18px;
  & ${Highlight} {
    color: ${theme.color.SECONDARY};
    font-size: 32px;
  }

  @media (max-width: 375px) {
    margin-right: 20px;
  }
`

export const CounterBox = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-self: end;
  align-self: flex-end;
  @media (max-width: 375px) {
    justify-self: center;
  }
  @media (max-width: 320px) {
    justify-self: start;
  }
`

export const SkipBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  justify-self: start;
  width: fit-content;
`

export const Link = styled.label`
  cursor: pointer;
  text-decoration: underline;
  color: ${theme.color.SECONDARY};
`

export const DiagnosticText = styled.span`
  display: block;
  width: 100%;
  color: ${theme.color.GREY300};
  font-size: 0.9rem;
  margin: 30px 1% 0px 1%;
  line-height: 18px;
  letter-spacing: 0.2px;
`

export const CorrectAnswerIndicator = styled.span`
  display: none;
  color: ${theme.color.GREEN};
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 11px;
  letter-spacing: 0.4px;
  font-weight: bold;
  text-transform: uppercase;
  transform: translateY(100%);
  padding-top: 5px;

  @media (max-width: 767px) {
    display: block;
  }
`
