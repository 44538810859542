import React, { useEffect, useState } from 'react'
import {
  CloseButton,
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Opacity,
  OkButton,
} from './styles'
import { ReactComponent as ConquerIcon } from '../../assets/svg/icon-conquer.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { ReactComponent as IconCheck } from '../../assets/svg/check.svg'

export default function ModalDefault(props) {
  const [hideModal, setHideModal] = useState(true)
  const [modalContent, setModalContent] = useState()

  useEffect(() => {
    if (props.modalContent) {
      setModalContent(props.modalContent)
    }
  }, [props.modalContent])

  useEffect(() => {
    if (props.hideModal != null) {
      setHideModal(props.hideModal)
    }
  }, [props.hideModal])

  function closeModal() {
    setHideModal(true)
    props.closeModal(true)
  }

  return (
    <>
      <Opacity hide={hideModal}>
        <Modal>
          <ModalHeader>
            <ModalTitle>
              <ConquerIcon width={48} />
            </ModalTitle>
            <CloseButton onClick={() => closeModal()}>
              <FontAwesomeIcon icon={faTimes} />
            </CloseButton>
          </ModalHeader>
          <ModalContent>{modalContent}</ModalContent>
          {props.okButton ? (
            <OkButton onClick={() => closeModal()}>
              ok
              <IconCheck />
            </OkButton>
          ) : (
            <></>
          )}
        </Modal>
      </Opacity>
    </>
  )
}
