import React from 'react'
import { Flex, Question, Divider, TitleQustion } from '../../assets/styles/global'
// import BallonMessage from '../BallonMessage';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from '../../styles/theme'

export default function RecordAudioAnswers(props) {
  function getRight() {
    for (let i = 0; i < props.question.answers.length; i++) {
      if (props.question.answers[i].right) {
        return props.question.answers[i]
      }
    }
    return null
  }

  function getWords(text) {
    let initialSplit = text.split(' ')
    let words = []
    for (let str of initialSplit) {
      if (str !== '') {
        words.push(str)
      }
    }
    return words
  }

  function getRightText(text) {
    return <span style={{ color: theme.color.GREEN }}>{text}</span>
  }

  function getWrongText(text) {
    return <span style={{ color: theme.color.RED_FAIL }}>{text}</span>
  }

  function highlight() {
    let result = []

    if (!props.question.userAnswer) {
      result.push(getWrongText(getRight().text))
      result.push(
        <FontAwesomeIcon icon={faCheckCircle} color={theme.color.GREEN}></FontAwesomeIcon>,
      )
      return result
    }
    let keywords = props.question.userAnswer.keywordsResult.keywords

    let rightWords = getWords(getRight().text)

    let wrongCounter = 0
    for (let i = 0; i < keywords.length; i++) {
      let item = keywords[i]
      if (item.match) {
        result.push(getRightText(rightWords[i]))
      } else {
        result.push(getWrongText(rightWords[i]))
        wrongCounter++
      }
      result.push(' ')
    }

    if (wrongCounter <= 2) {
      // show success check icon ignoring until 2 wrong answers
      result.push(
        <FontAwesomeIcon icon={faCheckCircle} color={theme.color.GREEN}></FontAwesomeIcon>,
      )
    } else {
      // show fail check icon
      result.push(
        <FontAwesomeIcon icon={faTimesCircle} color={theme.color.RED_FAIL}></FontAwesomeIcon>,
      )
    }

    return result
  }

  return (
    <>
      <Flex
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          marginBottom: '40px',
        }}
      >
        <TitleQustion>{props.question.text}</TitleQustion>
        <Question>{highlight()}</Question>
        <Divider />
      </Flex>
    </>
  )
}
