import React, { createContext, useState, useContext } from 'react'

const AudioError = createContext()

export default function AudioErrorProvider({ children }) {
  const [isAudioError, setIsAudioError] = useState(false)

  return (
    <AudioError.Provider value={{ isAudioError, setIsAudioError }}>{children}</AudioError.Provider>
  )
}

export function useAudioError() {
  const context = useContext(AudioError)
  const { isAudioError, setIsAudioError } = context
  return { isAudioError, setIsAudioError }
}
