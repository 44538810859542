import styled from 'styled-components'
import theme from '../../styles/theme'

export const HeaderBg = styled.div`
  background-color: ${theme.color.PRIMARY};
  height: 90px;
  width: 100%;
`

export const QuestionBox = styled.a`
  height: 100%;
  width: 60px;
  background-color: ${theme.color.SECONDARY};
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 22px;
  }
`

export const SideLeftMenu = styled.div`
  display: flex;
`

export const MenuItem = styled.div`
  background-color: ${theme.color.BLACK};
  padding: 24px;
  font-size: 20px;
  color: white;
  cursor: context-menu;
  font-weight: 300;
  margin-bottom: -20px;
  margin-left: 50px;
  border-radius: 18px 18px 0px 0px;
  user-select: none;
  & span {
    color: ${theme.color.SECONDARY};
  }
  @media (max-width: 900px) {
    font-size: 15px;
    margin-left: 30px;
  }
`
