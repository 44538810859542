import React, { useEffect, useState } from 'react'
import { Flex, Question, Divider, TitleQustion, TextAreaWithRows } from '../../assets/styles/global'

export default function TextQuestion(props) {
  const [text, setText] = useState('')

  function sendCallback(answerText) {
    setText(answerText)
    props.callBack({ questionId: props.question.id, answer: answerText })
    if (answerText.length > 5) {
      props.changeNext(false)
    } else {
      props.changeNext(true)
    }
  }

  useEffect(() => {
    setText('')
  }, [props.question])

  return (
    <>
      <Flex
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <TitleQustion>Escreva a seguinte frase em inglês:</TitleQustion>
        <Question>{props.question.text}</Question>
        <Divider />
        <TextAreaWithRows
          value={text}
          onChange={(e) => sendCallback(e.target.value)}
          autoComplete='off'
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck='false'
        />
      </Flex>
    </>
  )
}
