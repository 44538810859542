import styled from 'styled-components'
import theme from '../../styles/theme'

export const Ballon = styled.div`
  position: relative;
  width: 333px;
  border-radius: 6px;
  background-color: ${theme.color.WHITE};
  &:after {
    content: '';
    position: absolute;
    top: -15px;
    left: 45%;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid ${theme.color.WHITE};
  }
`
export const Column = styled.div`
  width: ${(props) => props.width || '0'};
`

export const BallonText = styled.div``

export const XButton = styled.div`
  border-radius: 40px;
  background-color: ${theme.color.RED_FAIL};
  color: ${theme.color.WHITE};
  width: 18px;
  text-align: center;
  padding: 2px;
  position: relative;
  top: 15px;
  left: 10px;
`

export const BallonTextContainer = styled.div`
  display: flex;
  padding: 10px;
`

export const ShowMore = styled.p`
  cursor: pointer;
  text-decoration: underline;
  color: ${theme.color.SECONDARY};
  display: ${(props) => (props.show ? '' : 'none')};
`
