import React from 'react'
import { BlockerBackground, Modal, Title, Description } from './styles'
import { useBlockerModal } from '../../context/blockerModal.context'

export default function BlockerModal() {
  const { blockerModal } = useBlockerModal()

  function handleLinkRedirect(e) {
    e.preventDefault()
    e.stopPropagation()
    window.open(process.env.REACT_APP_HELP_URL, '_blank')
  }

  function handlePageReload() {
    window.location.reload()
  }

  function getBody(type) {
    switch (type) {
      case 'low':
        return (
          <div>
            <Title>Heeeey!</Title>
            <Description>{blockerModal.text}</Description>
          </div>
        )
      case 'long':
        return (
          <>
            <div>
              <Title>Heeeey!</Title>
              <Description>{blockerModal.text1}</Description>
              <Description>{blockerModal.text2}</Description>
              <Description>
                <a href='#' onClick={(e) => handleLinkRedirect(e)}>
                  Se precisa de ajuda, clique aqui
                </a>
              </Description>
            </div>
          </>
        )
      default:
        return (
          <div>
            <Title>
              <br />
              Opssss!
            </Title>
            <Description>{blockerModal.text}</Description>

            <a href='#' onClick={(e) => handleLinkRedirect(e)}>
              Precisa de ajuda? clique aqui
            </a>

            <br />
            <br />
            <a href='#' onClick={(e) => handlePageReload(e)}>
              Ou tente recarregar a página clicando aqui
            </a>
          </div>
        )
    }
  }

  return (
    <BlockerBackground active={blockerModal.status}>
      <Modal>{getBody(blockerModal.type)}</Modal>
    </BlockerBackground>
  )
}
