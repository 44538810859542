import axios from 'axios'

const hook =
  'https://chat.googleapis.com/v1/spaces/AAAAYgaxMNA/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=o4WpwdKlngqitwr9gnVLxQ2rWUsyLj64MPrmcgWC7s0%3D'

export default async function NotificationService(message) {
  let resp = {}
  resp = await axios.post(hook, {
    text: message,
  })
  resp = resp?.data?.payload

  return resp
}
