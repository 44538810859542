export default {
  color: {
    WHITE: '#FFF',
    BLACK: '#000',
    BLACK690: '#00000069',
    MAIN_BG: '#161C24',
    SECONDARY_BG: '#212B36',
    BG_HEADER: '#63409A',
    PRIMARY: '#63409A',
    SECONDARY: '#C9DC44',
    GREY: '#141414',
    GREY100: '#b4b4b4',
    GREY300: '#8D8D8D',
    GREY500: '#707070',
    GREY550: '#545454',
    GREY600: '#3D3D3D',
    GREY625: '#2A2A2A',
    GREY650: '#252525',
    GREY700: '#262626',
    GREY_INPUT: '#4F4F4F',
    GREEN: '#2A9235',
    GREEN_SUCCESS: '#00a656',
    GREENCHECK: '#2EF142',
    YELLOW: '#FFFF00',
    RED_FAIL: '#f95252',
  },
}
