import React, { useState, useEffect } from 'react'
import {
  Flex,
  TitleQustion,
  Question,
  Message,
  RecordButton,
  MessageError,
} from '../../assets/styles/global'
import { ReactComponent as Microphone } from '../../assets/svg/microphone.svg'
import { ReactComponent as Poligono } from '../../assets/svg/poligono.svg'
import Lottie from 'react-lottie'
import RecordingData from '../../assets/json/recording.json'
import SuccessData from '../../assets/json/success.json'
import CountdownData from '../../assets/json/countdown.json'
import { useAudioError } from '../../context/audioError.context'
import useRecorder from '../../hooks/useRecord'
import theme from '../../styles/theme'

export default function RecordAudioQuestion(props) {
  const [recording, setRecording] = useState('stop')
  const [intentText, setIntentText] = useState('Clique para gravar sua resposta')
  const [isDisabled, setIsDisabled] = useState(false)
  const { isAudioError, setIsAudioError } = useAudioError()

  const [micError, audioURL, audioData, iniciar] = useRecorder()

  useEffect(() => {
    if (audioData != undefined) {
      props.callBack({ questionId: props.question.id, answer: audioData })
    }
  }, [audioData])

  const countdownOptions = {
    loop: false,
    autoplay: true,
    animationData: CountdownData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: RecordingData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const successAnimation = {
    loop: false,
    autoplay: true,
    animationData: SuccessData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    if (props.micError != undefined) {
      setIsDisabled(micError)

      if (props.onMicError) {
        props.onMicError(micError)
      }
    }
  }, [micError])

  useEffect(() => {
    if (audioData != undefined && props.onFinishRecording) {
      props.onFinishRecording(audioData)
    }
  }, [audioData])

  useEffect(() => {
    if (audioURL != undefined && props.onAudioURL) {
      props.onAudioURL(audioURL)
    }
  }, [audioURL])

  async function RecordAudio() {
    if (recording === 'countdown') return

    if (recording === 'recording') {
      setRecording('success')
      iniciar(false)
      setIntentText('Seu áudio foi gravado com sucesso!')
      props.setButtonColor(theme.color.SECONDARY)

      props.changeNext(false)
      setIsAudioError(false)
    } else {
      if (!isDisabled) {
        setIsAudioError(false)
        setRecording('countdown')
        setIntentText('Aguarde a contagem para falar')

        setTimeout(() => {
          iniciar(true)
          setTimeout(() => {
            setRecording('recording')
            setIntentText('Clique para parar de gravar')
          }, 1000)
        }, 2000)
      }
    }
  }

  return (
    <>
      <Flex
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <TitleQustion>Fale a frase abaixo</TitleQustion>
        <Question>{props.question.answers[0].text}</Question>

        <RecordButton onClick={() => RecordAudio()} recording={recording}>
          {recording === 'countdown' ? (
            <Lottie
              options={countdownOptions}
              height={100}
              width={100}
              isStopped={false}
              isPaused={false}
              isClickToPauseDisabled
            />
          ) : recording === 'recording' ? (
            <Lottie
              options={defaultOptions}
              height={100}
              width={100}
              isStopped={false}
              isPaused={false}
              isClickToPauseDisabled
            />
          ) : recording === 'success' ? (
            <Lottie
              options={successAnimation}
              height={100}
              width={100}
              isStopped={false}
              isPaused={false}
              isClickToPauseDisabled
            />
          ) : (
            <Microphone />
          )}
        </RecordButton>

        {isAudioError || micError ? (
          <MessageError>
            <Poligono />
            Ocorreu um erro ao processar o áudio.
            <br />
            Vamos tentar novamente?
          </MessageError>
        ) : (
          <Message>
            <Poligono />
            {intentText}
          </Message>
        )}
      </Flex>
    </>
  )
}
