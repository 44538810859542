import React, { useEffect, useState } from 'react'
import { ModalContentText, ModalContentTitle } from '../../assets/styles/global'

export default function AnswersModalContent(props) {
  const [answers, setAnswers] = useState([])

  function addAnswer() {
    let arr = []

    if (props.answers) {
      props.answers.forEach((answer) => {
        arr.push(<ModalContentText>{answer.text}</ModalContentText>)
      })

      setAnswers(arr)
    }
  }

  useEffect(() => {
    addAnswer()
  }, [props.answers])

  return (
    <>
      <ModalContentTitle>Confira todas as frases corretas:</ModalContentTitle>
      {answers}
    </>
  )
}
