import React, { useState, useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useUtm } from '../../hooks/useUtm'
import { ReactComponent as IBMLogo } from '../../assets/svg/ibm-logo.svg'
import { ReactComponent as IconNext } from '../../assets/svg/button-next.svg'
import {
  ContentHeader,
  Container,
  SmallText,
  TitleHeader,
  IBMLogoBox,
  BreadCrumb,
  Flex,
  BreadCrumbBox,
  TableDiagnostic,
  InputDiagnostic,
  Label,
  Input,
  RowForm,
  BtnNext,
  ValidationError,
  DiagnosticText,
  SmallTextBold,
  TextBold,
} from '../../assets/styles/global'
import { Animated } from 'react-animated-css'
import axios from 'axios'
import { useBlockerModal } from '../../context/blockerModal.context'
import { setPersistedState } from 'persisted-state'
import ModalDefault from '../../components/ModalDefault'
import StudentModalContent from '../../components/StudentModalContent'
import RedoDiagnosticModalContent from '../../components/RedoDiagnosticModalContent'
import PhoneInput2 from '../../components/PhoneInput2'
import { validate as EmailValidator } from 'email-validator'
import Checkbox from '../../components/Checkbox'
import Lottie from 'react-lottie'
import animationLogo4 from '../../assets/json/logo-4.json'
import NotificationService from '../../services/NotificationService'
import theme from '../../styles/theme'

export default function FormLead() {
  const [info, setInfo] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isStudent, setIsStudent] = useState(null)
  const [hideModal, setHideModal] = useState(true)
  const [contentModal, setContentModal] = useState()
  const [validations, setValidations] = useState({})
  const [redirectMicTest, setRedirectMicTest] = useState(false)
  const [canStartNewDiagnostic, setCanStartNewDiagnostic] = useState(null)
  const [isNumberInsertedByUserValid, setIsNumberInsertedByUserValid] = useState({
    message: '',
    valid: true,
    insert: false,
  })

  const [isEmailInsertedByUserValid, setIsEmailInsertedByUserValid] = useState({
    message: '',
    valid: true,
    insert: false,
  })

  const { studentId } = useParams()

  const [diagnosticId, setDianosticId] = useState()
  const [startButtonText, setStartButtonText] = useState('INICIAR O TESTE')

  const { setBlockerModal } = useBlockerModal()

  const utm = useUtm()

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: animationLogo4,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  function checkIfUserIsAlreadyStudent(studentId) {
    if (studentId) {
      axios
        .get(`${process.env.REACT_APP_HOST}/student/${studentId}`)
        .then((resp) => {
          if (resp.data.englishStudent) {
            setCanStartNewDiagnostic(resp.data.canStartNewDiagnostic)
          }
          setIsStudent(resp.data.englishStudent)
        })
        .finally(() => {
          // this way we dont block the person from try a new diagnostic
          setIsLoading(false)
        })
    }
  }

  function handleInfo(e) {
    var name = e.target.name
    var value = e.target.value
    delete validations[name]
    setValidations(validations)

    setInfo((prev) => ({ ...prev, [name]: value }))
  }

  function formSubmittedEvent() {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'form_submitted',
      form_page: document.title,
      form_name: 'conquer_english_nivelamento',
      form_type: 'start',
      tel: info?.phone,
      email: info?.email,
      // uniqueEventId: "10544"
    })
  }

  async function submitInfo() {
    if (!isNumberInsertedByUserValid.insert || !isNumberInsertedByUserValid.valid) {
      return
    }

    if (!isEmailInsertedByUserValid.insert || !isEmailInsertedByUserValid.valid) {
      return
    }

    setStartButtonText('INICIANDO')
    submitLead(utm, info)
    formSubmittedEvent()

    try {
      createDiagnostic(info.name, info.email, info.phone)
    } catch (error) {
      if (error.response.status === 400) {
        let payload = error.response.data.payload
        let validations = {}
        if (payload) {
          for (let index in payload) {
            let validation = payload[index]
            validations[validation.field] = validation.defaultMessage
          }
          setValidations(validations)
        } else if (error.response.data.msg && error.response.data.msg === 'Email já cadastrado') {
          setContentModal(<StudentModalContent />)
          setHideModal(false)
        } else if (error.response.data.msg && error.response.data.msg.includes('aluno informado')) {
          setContentModal(<RedoDiagnosticModalContent />)
          setHideModal(false)
        }
      } else if (error.response.status === 500) {
        setBlockerModal({
          status: true,
          text: 'Ops! Algo deu errado, tente novamente.',
        })

        info.userAgent = window.navigator.userAgent
        info.error = error?.response?.data?.msg || ''
        let infoStr = JSON.stringify(info)

        NotificationService('Erro ao tentar submeter o form do diagnostico ' + infoStr)
      }
    }
  }

  async function createDiagnostic(name, email, phone) {
    const payload = {
      name: name,
      email: email,
      phone: phone,
      termAcceptanceLGPD: true,
      timeStudiedEnglish: 'NA',
      mainObjectiveStudyingEnglish: 'NA',
      stuckEnglishJustify: 'NA',
      difficultyToSpeakEnglish: 'NA',
      origin: 'RECORDED_ENGLISH',
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST}/english-diagnostic/sociodemographic/profile`,
        payload,
      )
      setDianosticId(response.data.payload.diagnosticId)
      setRedirectMicTest(true)
    } catch (error) {
      if (error && error.response && error.response.status === 400) {
        if (error.response.data.msg && error.response.data.msg === 'Email já cadastrado') {
          setContentModal(<StudentModalContent />)
        } else if (error.response.data.msg && error.response.data.msg.includes('aluno informado')) {
          setContentModal(<RedoDiagnosticModalContent />)
        } else {
          setContentModal(
            <StudentModalContent text='Ops! Algo deu errado, tente novamente. Err: 4001' />,
          )
        }
        setHideModal(false)
      } else if (error && error.response && error.response.status === 500) {
        setBlockerModal({
          status: true,
          text: 'Ops! Algo deu errado, tente novamente. Err: 5001',
        })
      } else {
        // setBlockerModal({ status: true, text: "Ops! Algo deu errado, tente novamente." })
        setContentModal(
          <StudentModalContent text='Ops! Algo deu errado, tente novamente. Err: 5002' />,
        )
        setHideModal(false)
      }
    }
  }

  async function submitLead(utm, info) {
    try {
      const payload = {
        utmCampaign: utm?.campaign,
        utmSource: utm?.source,
        utmMedium: utm?.medium,
        utmId: utm?.id,
        utmContent: utm?.content,
        utmTerm: utm?.term,
        campaignId: process.env.REACT_APP_LEAD_CAMPAIGN_ID,
        name: info?.name,
        phone: info?.phone,
        email: info?.email,
        confirmEmail: info?.email,
        nivelIngles: null,
        lgp: true,
        lgpdChecks: ['1f9a6c39-3d8e-11ec-a027-025a6def0c2b'],
      }
      setPersistedState('payload', JSON.stringify(payload))
      await axios.post(`${process.env.REACT_APP_BACKEND_MKT}/lead-api/lead`, payload)
    } catch (err) {
      // evitamos que a pessoa seja bloqueada de realizar o teste de nivelamento!!
      console.error('err', err)
    }
  }

  function validateBrazilNumberLength(phone) {
    const countryCode = phone.slice(0, 2)

    if (countryCode !== '55') {
      return true
    }

    if (phone.length < 13) {
      return false
    }

    return true
  }

  function validateNumberDDD(phone) {
    const countryCode = phone.slice(0, 2)

    if (countryCode !== '55') {
      return true
    }

    const availablePrefixCodeOfStates = [
      61, 62, 64, 65, 66, 67, 82, 71, 73, 74, 75, 77, 85, 88, 98, 99, 83, 81, 87, 86, 89, 84, 79,
      68, 96, 92, 97, 91, 93, 94, 69, 95, 63, 27, 28, 31, 32, 33, 34, 35, 37, 38, 21, 22, 24, 11,
      12, 13, 14, 15, 16, 17, 18, 19, 41, 42, 43, 44, 45, 46, 51, 53, 54, 55, 47, 48, 49,
    ]

    const statePrefixCode = phone.slice(2, 4)

    if (availablePrefixCodeOfStates.includes(parseInt(statePrefixCode))) {
      return true
    }

    return false
  }

  function onNumberInputBlur() {
    if (!validateBrazilNumberLength(info.phone)) {
      return setIsNumberInsertedByUserValid({
        message: 'Formato de telefone inválido',
        valid: false,
        insert: true,
      })
    }

    if (!validateNumberDDD(info.phone)) {
      return setIsNumberInsertedByUserValid({
        message: 'Insira um DDD válido',
        valid: false,
        insert: true,
      })
    }

    if (info.phone === '') {
      return setIsNumberInsertedByUserValid({
        message: 'Campo obrigatório',
        valid: false,
        insert: false,
      })
    }

    setIsNumberInsertedByUserValid({
      message: '',
      valid: true,
      insert: true,
    })
  }

  function validateEmailFormat() {
    // ? Email format: Recipient | @ Symbol | Domain name | Top-level domain
    let valid = true

    if (!EmailValidator(info.email)) {
      valid = false
    }

    const withNoSpecialCharacters = new RegExp(/^([a-zA-Z0-9\.\-\_]+)$/g)

    const address = info.email.split('@')[0]

    if (!withNoSpecialCharacters.test(address)) {
      valid = false
    }

    if (!valid) {
      return setIsEmailInsertedByUserValid({
        message: 'Formato de e-mail inválido',
        valid,
        insert: true,
      })
    } else {
      return setIsEmailInsertedByUserValid({
        message: '',
        valid,
        insert: true,
      })
    }
  }

  useEffect(() => {
    if (isStudent != null && !isStudent) {
      setIsLoading(false)
    }
  }, [isStudent])

  useEffect(() => {
    if (canStartNewDiagnostic != null) {
      if (!canStartNewDiagnostic) {
        setContentModal(<RedoDiagnosticModalContent />)
        setHideModal(false)
      } else {
        setRedirectMicTest(true)
      }
    }
  }, [canStartNewDiagnostic])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (studentId) {
      setTimeout(() => {
        checkIfUserIsAlreadyStudent(getParameterByName('code') || studentId)
        setIsLoading(false)
      }, 1000)
    } else {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [studentId])

  return (
    <>
      {redirectMicTest && studentId && diagnosticId ? (
        <Navigate to={`/student/${studentId}/diagnostic/${diagnosticId}/mic-test`} />
      ) : null}
      {redirectMicTest && diagnosticId ? (
        <Navigate to={`/diagnostic/${diagnosticId}/mic-test`} />
      ) : null}
      <ContentHeader>
        <Container>
          <Animated
            animationIn='fadeIn'
            animationOut='fadeIn'
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={true}
          >
            <SmallText>TESTE DE INGLÊS</SmallText>
            <TitleHeader>
              GRÁTIS, ONLINE<span> E RÁPIDO!</span>
            </TitleHeader>
            <SmallTextBold>
              Seja bem-vindo ao teste de nivelamento da Conquer English!
            </SmallTextBold>
          </Animated>
        </Container>
        <Animated
          animationIn='fadeIn'
          animationOut='fadeIn'
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={true}
        >
          <BreadCrumbBox>
            <Flex
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <BreadCrumb active={true}>YOU</BreadCrumb>
              <BreadCrumb>YOUR SKILLS</BreadCrumb>
              <BreadCrumb>RESULTS</BreadCrumb>
            </Flex>
          </BreadCrumbBox>
          <IBMLogoBox>
            <IBMLogo />
          </IBMLogoBox>
        </Animated>
      </ContentHeader>

      <Flex
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className='bg_secondary'
      >
        <TableDiagnostic>
          {isLoading ? (
            <RowForm id='row-form-1' style={{ justifyContent: 'center' }}>
              <>
                <div style={{ position: 'relative' }}>
                  <div
                    style={{
                      color: theme.color.WHITE,
                      top: '-22px',
                      left: '23px',
                      position: 'absolute',
                    }}
                  >
                    Carregando...
                  </div>
                  <Lottie
                    options={loadingOptions}
                    height={200}
                    isStopped={false}
                    isPaused={false}
                    isClickToPauseDisabled
                  />
                </div>
              </>
            </RowForm>
          ) : (
            <Animated
              animationIn='fadeIn'
              animationOut='fadeIn'
              animationInDuration={1000}
              animationOutDuration={1000}
              isVisible={true}
            >
              <RowForm>
                <TextBold>
                  Descubra o nível do seu inglês com{' '}
                  <span className='markdown'>100% de eficácia</span> e faça como mais de
                  <span className='bold'> 7.000 alunos</span> da Conquer English!
                </TextBold>
                <InputDiagnostic
                  style={{
                    width: '98%',
                  }}
                >
                  <Label>NOME</Label>
                  <Input
                    type='text'
                    placeholder='Escreva seu nome completo'
                    name='name'
                    onChange={(e) => handleInfo(e)}
                  />
                  <ValidationError display={validations['name']}>
                    {validations['name']}
                  </ValidationError>
                </InputDiagnostic>

                <InputDiagnostic
                  style={{
                    width: '98%',
                  }}
                >
                  <Label>E-MAIL</Label>
                  <Input
                    type='text'
                    placeholder='Escreva seu e-mail'
                    name='email'
                    onChange={(e) => handleInfo(e)}
                    onBlur={validateEmailFormat}
                  />
                  <ValidationError display={!isEmailInsertedByUserValid.valid}>
                    {isEmailInsertedByUserValid.message}
                  </ValidationError>
                </InputDiagnostic>
                <InputDiagnostic
                  style={{
                    width: '98%',
                  }}
                >
                  <Label style={{ marginBottom: '10px' }}>TELEFONE</Label>
                  <PhoneInput2
                    name='phone'
                    onBlur={onNumberInputBlur}
                    onChange={handleInfo}
                    phone={info?.phone}
                    value={info?.phone}
                  >
                    {' '}
                  </PhoneInput2>
                  <ValidationError display={!isNumberInsertedByUserValid.valid}>
                    {isNumberInsertedByUserValid.message}
                  </ValidationError>
                </InputDiagnostic>

                <DiagnosticText>
                  Ao informar seus dados e seguir para a próxima etapa, você concorda com a nossa{' '}
                  <a
                    href='https://escolaconquer.com.br/POLÍTICA_DE_PRIVACIDADE_E_COOKIES-CONQUER.pdf'
                    target='_blank'
                    style={{ textDecoration: 'none', color: theme.color.WHITE }}
                    rel='noreferrer'
                  >
                    Política de Privacidade.
                  </a>
                  <br />
                  <div style={{ marginTop: '10px' }}>
                    <Checkbox
                      name='termAcceptanceLGPD'
                      text='Aceito os termos'
                      onValueChanged={handleInfo}
                    ></Checkbox>
                  </div>
                  <br />
                  <ValidationError display={validations['termAcceptanceLGPD']}>
                    {validations['termAcceptanceLGPD']}
                  </ValidationError>
                </DiagnosticText>
              </RowForm>
            </Animated>
          )}
        </TableDiagnostic>
      </Flex>

      <>
        <Flex
          style={{
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
          className='bg_secondary'
        >
          <BtnNext onClick={() => submitInfo()}>
            {startButtonText}
            <IconNext />
          </BtnNext>
        </Flex>
      </>
      <ModalDefault
        hideModal={hideModal}
        modalContent={contentModal}
        closeModal={(e) => setHideModal(e)}
      />
    </>
  )
}
