import styled from 'styled-components'
import theme from '../../styles/theme'

export const CongratulationsText = styled.h1`
  color: ${theme.color.SECONDARY};
  text-align: center;
`

export const Text = styled.div`
  color: ${theme.color.WHITE};
  text-align: center;
  padding: 11px;
`
