import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import FormLead from './pages/FormLead'
import Header from './components/header'
import Footer from './components/footer'
import Skills from './pages/skills'
import Finish from './pages/Finish'
import MicTest from './pages/MicTest'
import FinishProvider from './context/finish.context'
import BlockerModalProvider from './context/blockerModal.context'
import AudioErrorProvider from './context/audioError.context'
import GlobalStyle from './styles/global'

import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

export default function App() {
  return (
    <FinishProvider>
      <BlockerModalProvider>
        <AudioErrorProvider>
          <Router>
            <GlobalStyle />
            <Header />
            <Routes>
              <Route path='/' element={<FormLead />} />
              <Route path='/teste-de-ingles' element={<FormLead />} />
              <Route path='/teste-de-ingles/' element={<FormLead />} />
              <Route path='/diagnostic/:diagnosticId/teste-de-ingles' element={<FormLead />} />

              <Route path='/student/:studentId/mic-test' element={<MicTest />} />
              <Route
                path='/student/:studentId/diagnostic/:diagnosticId/mic-test'
                element={<MicTest />}
              />
              <Route path='/diagnostic/:diagnosticId/mic-test' element={<MicTest />} />
              <Route path='/mic-test' element={<MicTest />} />

              <Route path='/your-skills' element={<Skills />} />
              <Route path='/diagnostic/:diagnosticId/your-skills' element={<Skills />} />
              <Route
                path='/student/:studentId/diagnostic/:diagnosticId/your-skills'
                element={<Skills />}
              />

              <Route path='/results' element={<Finish />} />
              <Route path='/diagnostic/:diagnosticId/result' element={<Finish />} />
              <Route path='/:diagnosticId/result' element={<Finish />} />
              <Route
                path='/student/:studentId/diagnostic/:diagnosticId/result'
                element={<Finish />}
              />
            </Routes>
            <Footer />
          </Router>
        </AudioErrorProvider>
      </BlockerModalProvider>
    </FinishProvider>
  )
}
