import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import theme from '../../styles/theme'
HighchartsMore(Highcharts)

export default function Chart(props) {
  const [categories, setCategories] = useState([
    'Pronunciation',
    'Comprehension',
    'Vocabulary Range',
    'Accuracy',
  ])

  useEffect(() => {
    if (props.categories != null) {
      setCategories(props.categories)
    }
  }, [props.categories])

  const [series, setSeries] = useState([
    {
      name: 'Resultado',
      data: [20, 30, 40, 50],
      color: theme.color.SECONDARY,
      pointPlacement: 'on',
    },
  ])

  useEffect(() => {
    if (props.series != null) {
      setSeries(props.series)
    }
  }, [props.series])

  const [title, setTitle] = useState({
    text: undefined,
  })

  useEffect(() => {
    if (props.title != null) {
      setTitle(props.title)
    }
  }, [props.title])

  const [chart, setChart] = useState({
    polar: true,
    type: 'line',
    backgroundColor: theme.color.SECONDARY_BG,
    width: 420,
  })

  useEffect(() => {
    if (props.chart != null) {
      setChart(props.chart)
    }
  }, [props.chart])

  const [accessibility, setAccessibility] = useState({
    description: 'Resultado do diagnóstico',
  })

  useEffect(() => {
    if (props.accessibility != null) {
      setAccessibility(props.accessibility)
    }
  }, [props.accessibility])

  const [pane, setPane] = useState({
    size: '60%',
  })

  useEffect(() => {
    if (props.pane != null) {
      setPane(props.pane)
    }
  }, [props.pane])

  const [xAxis, setXAxis] = useState({
    categories: categories,
    tickmarkPlacement: 'on',
    lineWidth: 0,
  })

  useEffect(() => {
    if (props.xAxis != null) {
      setXAxis(props.xAxis)
    }
  }, [props.xAxis])

  useEffect(() => {
    setXAxis({
      categories: categories,
      tickmarkPlacement: 'on',
      lineWidth: 0,
    })
  }, [categories])

  const [yAxis, setYAxis] = useState({
    gridLineInterpolation: 'polygon',
    lineWidth: 0.5,
    min: 0,
  })

  useEffect(() => {
    if (props.yAxis != null) {
      setYAxis(props.yAxis)
    }
  }, [props.yAxis])

  const [tooltip, setTooltip] = useState({
    shared: true,
    backgroundColor: theme.color.MAIN_BG,
    style: {
      color: theme.color.WHITE,
    },
    pointFormat:
      '<span style="color:' + theme.color.SECONDARY + '">{series.name}: <b>{point.y}</b><br/>',
  })

  useEffect(() => {
    if (props.tooltip != null) {
      setTooltip(props.tooltip)
    }
  }, [props.tooltip])

  const [legend, setLegend] = useState({
    align: 'right',
    verticalAlign: 'top',
    layout: 'horizontal',
    itemStyle: {
      color: theme.color.WHITE,
    },
    itemHoverStyle: {
      color: theme.color.WHITE,
    },
    alignColumns: false,
  })

  useEffect(() => {
    if (props.legend != null) {
      setLegend(props.legend)
    }
  }, [props.legend])

  const [exporting, setExporting] = useState({
    enabled: false,
  })

  useEffect(() => {
    if (props.exporting != null) {
      setExporting(props.exporting)
    }
  }, [props.exporting])

  const [credits, setCredits] = useState({
    enabled: false,
  })

  useEffect(() => {
    if (props.credits != null) {
      setCredits(props.credits)
    }
  }, [props.credits])

  const [chartOptions, setChartOptions] = useState({
    title: title,

    chart: chart,

    accessibility: accessibility,

    pane: pane,

    xAxis: xAxis,

    yAxis: yAxis,

    tooltip: tooltip,

    legend: legend,

    series: series,

    exporting: exporting,

    credits: credits,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 390,
          },
          chartOptions: {
            chart: {
              width: 300,
            },
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal',
            },
            pane: {
              size: '20%',
            },
            subtitle: {
              text: null,
            },
            credits: {
              enabled: false,
            },
          },
        },
      ],
    },
  })

  useEffect(() => {
    if (props.chartOptions != null) {
      setChartOptions(props.chartOptions)
    }
  }, [props.chartOptions])

  useEffect(() => {
    setChartOptions({
      title: title,

      chart: chart,

      accessibility: accessibility,

      pane: pane,

      xAxis: xAxis,

      yAxis: yAxis,

      tooltip: tooltip,

      legend: legend,

      series: series,

      exporting: exporting,

      credits: credits,

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 390,
            },
            chartOptions: {
              chart: {
                width: 300,
              },
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
              },
              pane: {
                size: '20%',
              },
              subtitle: {
                text: null,
              },
              credits: {
                enabled: false,
              },
            },
          },
        ],
      },
    })
  }, [
    title,
    chart,
    accessibility,
    pane,
    xAxis,
    yAxis,
    tooltip,
    legend,
    series,
    exporting,
    credits,
    categories,
  ])

  return <>{<HighchartsReact highcharts={Highcharts} options={chartOptions} />}</>
}
