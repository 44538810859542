import React, { useEffect } from 'react'
import { Container, Flex } from '../../assets/styles/global'
import { HeaderBg, QuestionBox, SideLeftMenu } from './styles'
import { ReactComponent as QuestionCircle } from '../../assets/svg/question-solid.svg'
import { useBlockerModal } from '../../context/blockerModal.context'
import BlockerModal from '../../components/BlockerModal'

const Header = () => {
  const { blockerModal } = useBlockerModal()

  useEffect(() => {
    var is_chrome = navigator.userAgent.indexOf('CriOS') > -1
    var is_safari = navigator.userAgent.indexOf('Safari') > -1
    // var iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
    if (is_chrome && is_safari) {
      is_safari = false
    }
    // if (iOS && is_chrome) {
    //   setBlockerModal({
    //     status: true,
    //     text: 'Podemos te dar uma dica? Sua experiência na nossa plataforma será muito melhor pelo Safari ou pelo computador. Nos encontramos lá?',
    //     type: 'low',
    //   })
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HeaderBg>
      <Container style={{ height: '100%' }}>
        <Flex
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <SideLeftMenu>
            <img
              src={'/logo-english-new-brand.png'}
              alt='Logo Conquer English'
              style={{ width: 'auto', height: '52px' }}
            />
            {/* <MenuItem>CONQUER<span>ENGLISH</span></MenuItem> */}
          </SideLeftMenu>
          <QuestionBox href={process.env.REACT_APP_HELP_URL} target='_blank'>
            <QuestionCircle />
          </QuestionBox>
        </Flex>
      </Container>
      <BlockerModal active={blockerModal.status} />
    </HeaderBg>
  )
}

export default Header
