import React from 'react'
import { ModalContentText, ModalContentTitle } from '../../assets/styles/global'

export default function StudentModalContent(props) {
  return (
    <>
      <ModalContentTitle>Hey!</ModalContentTitle>
      <ModalContentText>
        {props.text ? (
          props.text
        ) : (
          <span>
            Para continuar o diagnóstico de inglês você precisa informar outro e-mail, o e-mail
            informado já foi utilizado anteriormente.
          </span>
        )}
      </ModalContentText>
    </>
  )
}
