import styled from 'styled-components'
import theme from '../../styles/theme'

export const H2 = styled.h2`
  font-weight: bold;
  color: white;
  text-align: center;
  padding: 30px 0 20px 0;
`

export const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.justifyContent || ''};
  min-height: ${(props) => props.height || ''};
  position: ${(props) => props.position || ''};
`

export const ResultadoTexto = styled.div`
  display-content: left;
`

export const ResultadoGrafico = styled.div`
  @media (min-width: 576px) {
    max-width: 100%;
  }

  @media (min-width: 768px) {
    max-width: 100%;
  }

  @media (min-width: 992px) {
    max-width: 100%;
  }

  @media (min-width: 1200px) {
    margin-left: 15px;
    max-width: 60%;
  }

  @media (min-width: 1336px) {
    margin-left: 15px;
    max-width: 60%;
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  position: relative;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 200px;
  background-color: ${(props) => props.backgroundColor || ''};
  height: ${(props) => (props.heightFull ? '100%' : 'auto')};
  @media (max-width: 575px) {
    max-width: 90%;
  }

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 576px;
  }

  @media (min-width: 992px) {
    max-width: 672px;
  }

  @media (min-width: 1200px) {
    max-width: 917px;
  }
`

export const SpanTitle = styled.h2`
  color: ${theme.color.SECONDARY};
  font-size: 18px;
  font-weight: normal;
  margin-bottom: -3px;
  display: inline-block;
`

export const SpanTitleTranslate = styled.h3`
  color: ${theme.color.PRIMARY};
  font-size: 18px;
  font-weight: normal;
  margin-bottom: -3px;
  margin-left: 10px;
  display: inline-block;
`

export const SpanExplan = styled.p`
  color: ${theme.color.WHITE};
  font-size: 16px;
  margin-bottom: 30px;
  font-weight: 200;
`

export const Grafico = styled.div`
  width: 100px;
  height: 100px;
`

export const ResultadoEscrito = styled.div`
  @media (min-width: 576px) {
    max-width: 100%;
  }

  @media (min-width: 768px) {
    max-width: 100%;
  }

  @media (min-width: 992px) {
    max-width: 100%;
  }

  @media (min-width: 1200px) {
    width: 50%;
  }

  @media (min-width: 1336px) {
    width: 50%;
  }
`

export const TitleTrilha = styled.h2`
  color: ${theme.color.SECONDARY};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 70px 0 50px 0;
`

export const DescritionText = styled.div`
  color: ${theme.color.GREY100};
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 8px;
`

export const Ability = styled.h2`
  color: ${theme.color.SECONDARY};
  font-size: 26px;
  font-weight: normal;
  text-align: left;
  margin-right: 20px;
`

export const AbilityExplan = styled.div`
  color: ${theme.color.SECONDARY};
  font-size: 16px;
  margin-bottom: 70px;
  margin-top: 20px;
  font-weight: 200;
`

export const FlexBox2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justfy-content: space-between;

  @media (min-width: 576px) and (max-width: 991px) {
    margin-right: auto;
    margin-left: 0;
  }
  @media (min-width: 992px) {
    margin-left: ${(props) => props.mLeft || ''};
  }

  @media (min-width: 1200px) {
    margin-left: ${(props) => props.mLeft || ''};
  }

  @media (min-width: 1336px) {
    margin-left: ${(props) => props.mLeft || ''};
  }
`

export const ProgressBar = styled.div`
  background-color: ${theme.color.GREY100};
  position: relative;
  display: flex;
  width: 200px;
  height: 10px;
  border-radius: 5px;
  transition: width 0.6s ease;
  margin: auto 0 auto auto;
`

export const Progress = styled.div`
  width: ${(props) => props.width + '%' || ''};
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${theme.color.SECONDARY};
  border-radius: 5px;
`

export const DescritionText2 = styled.div`
  color: ${theme.color.GREY100};
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 8px;
  text-align: left;
`

export const BotaoVerResultado = styled.button`
  background-color: ${theme.color.GREY300};
  text-align: center;
  height: 40px;
  width: 300px;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  margin: auto;
  border: 0;
  display: ${(props) => (props.display ? 'none' : 'block')};
  &:hover {
    cursor: pointer;
  }
`

export const BotaoIniciarCurso = styled.button`
  background-color: ${theme.color.SECONDARY};
  color: black;
  font-weight: 200;
  font-size: large;
  width: 200px;
  height: 50px;
  border-radius: 25px;
  float: right;
  margin-left: auto;
  margin-top: 50px;
  border: 0;
  cursor: pointer;
`

export const BoxResultado = styled.div`
  border: 1px solid ${theme.color.PRIMARY};
  border-radius: 20px;
  min-height: 500px;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  position: ${(props) => props.position || ''};
  @media (max-width: 575px) {
    max-width: 95%;
  }

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 576px;
  }

  @media (min-width: 992px) {
    max-width: 672px;
  }

  @media (min-width: 1200px) {
    max-width: 798px;
  }

  @media (min-width: 1336px) {
    max-width: 917px;
  }
`

export const BotaoControle = styled.button`
  width: 105px;
  height: 45px;
  position: absolute;
  left: ${(props) => (props.side === 'left' ? '-30px' : '')};
  right: ${(props) => (props.side === 'right' ? '-30px' : '')};
  background: ${theme.color.PRIMARY} 0% 0% no-repeat padding-box;
  border-radius: 53px;
  bottom: 20px;
  opacity: 1;
  margin-bottom: 30px;
  border: 0;
  color: ${theme.color.WHITE};
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`

export const ResultadoNivel = styled.span`
  margin: auto 0 auto 10px;
  color: ${theme.color.GREY100};
  font-size: 16px;
`

export const Secondary = styled.span`
  color: ${theme.color.SECONDARY} !important;
`

export const BoxCounter = styled.div`
  display: flex;
  margin-bottom: auto;
  margin-top: 12px;
  justify-content: flex-end;
  height: 28px;
`

export const Counter = styled.div`
  font-size: 24px;
  display: flex;
  margin-right: 12px;
  align-items: flex-end;
  justify-content: center;
  color: ${theme.color.GREY100};
`
