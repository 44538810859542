import { useCallback, useEffect, useState } from 'react'

const useRecorder = () => {
  const [audioURL, setAudioURL] = useState('')
  const [audioData, setAudioData] = useState()
  const [isRecording, setIsRecording] = useState(false)
  const [recorder, setRecorder] = useState()
  const [micError, setMicError] = useState(false)

  useEffect(() => {
    setMicError(false)
    requestRecorder().catch(() => {
      setMicError(true)
    })
  }, [])

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (!recorder) {
      if (isRecording) {
        requestRecorder()
          .then(setRecorder)
          .catch(() => {
            setMicError(true)
          })
      }
      return
    }

    // Manage recorder state.
    if (recorder && isRecording) {
      recorder.start()
    } else if (recorder && !isRecording) {
      if (recorder.state == 'recording') {
        recorder.stop()
      }
    }

    const blobToBase64 = (blob) => {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob.data)
        reader.onloadend = function () {
          resolve(reader.result)
        }
      })
    }

    // Obtain the audio when ready.
    const handleData = async (e) => {
      const b64 = await blobToBase64(e)
      setAudioData(b64)
      setAudioURL(URL.createObjectURL(e.data))
    }

    if (recorder) {
      recorder.addEventListener('dataavailable', handleData)
    }
    return () =>
      recorder != undefined ? recorder.removeEventListener('dataavailable', handleData) : undefined
  }, [recorder, isRecording])

  const iniciar = useCallback((val) => setIsRecording(val), [])

  return [micError, audioURL, audioData, iniciar]
}

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
  return new MediaRecorder(stream)
}

export default useRecorder
