import { React, useEffect, useState } from 'react'

export default function Checkbox(props) {
  const [checked, setChecked] = useState(true)

  function handleOnChange() {
    setChecked(!checked)
  }

  useEffect(() => {
    if (props.onValueChanged) {
      let value = {
        target: {
          name: props.name,
          value: checked,
        },
      }
      props.onValueChanged(value)
    }
  }, [checked])

  return (
    <>
      <input
        type='checkbox'
        name={props.name}
        defaultChecked={checked}
        onChange={handleOnChange}
      ></input>{' '}
      {props.text}
    </>
  )
}
